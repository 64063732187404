<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar nuevo
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
          pago
        </h3>
      </div>
    </div>

    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">

        <div class="column">
<!--
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="payment_form_customer_id">Cliente</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">

                <multiselect v-model="form.customer_id" id="payment_form_customer_id" :options="customers_list" 
                :custom-label="customers_menu_labels" :show-labels="false" style="max-width: 30em" placeholder=""
                :disabled="this.form.mode=='edit'">
                </multiselect>
                  <span class="help is-danger" v-if="errors.has('customer_id')" v-text="errors.get('customer_id')"></span>
                </div>
              </div>
            </div>
          </div>
-->

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="payment_form_amount">Importe</label>
            </div>
            <div class="field-body">
              <div class="field has-addons"> 
                <div class="control has-icons-left">
                  <input v-model="form.amount" placeholder="100" id="payment_amount" class="input " name="amount">
                  <span class="icon is-small is-left">
                    <i class="fa fa-money"></i>
                  </span>
                  <span class="help is-danger" v-if="errors.has('amount')" v-text="errors.get('amount')">
                  </span>
                </div>
                <div class="control">
                  <div class="select">
                    <select v-model="form.amount_currency"  id="payment_amount_currency" class="input" name="amount_currency">
                      <option v-for="currency in currency_list" :value="currency" :key="currency">
                        {{ currency }}
                      </option>
                    </select>
                  </div>
                  <span class="help is-danger" v-if="errors.has('amount_currency')" v-text="errors.get('amount_currency')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="payment_form_date">Fecha de recepcion </label>
            </div>
            <div class="field-body">
              <datetime v-model="form.date" type="datetime" input-class="input" v-bind:max-date="maxDate()" moment-locale="es" auto-continue>
              </datetime>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="payment_form_image">Comprobante de pago</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-upload name="image" @change.native="onImageChange">
                  <a class="button is-small">
                    <b-icon icon="upload" pack="fa" size="is-small"></b-icon>
                    <span>Comprobante</span>
                  </a>
                </b-upload>
                <div class="col-md-3" v-if="form.image_url">
                  <img :src="form.image_url" class="img-responsive" >
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="payment_form_confirmed">Confirmacion</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                <b-switch size="is-small is-info" v-model="form.confirmed">
                </b-switch>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="payment_form_reference">Referencia</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input v-model="form.reference" placeholder="000001" id="payment_form_reference" class="input" name="reference">
                  <span class="help is-danger" v-if="errors.has('reference')" v-text="errors.get('reference')"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import { Datetime } from 'vue-datetime';
  import moment from 'moment';
  export default {
    props: ['form', 'list', 'customer_id'],
    data: function () {
      return {
        currency_list: ['USD', 'MXN'],
        errors: this.form.errors
      }
    },
    components: {
      datetime: Datetime

    },

    methods: {
      maxDate: function() {
			  return moment().add(15, 'days').toISOString()
      },
      fix_amount_before_edit: function() {
        if (this.form.mode == 'edit') {
          this.form.amount = this.form.amount_value;
        }
      },
      fix_amount_after_edit: function() {
        if (this.form.mode == 'edit') {
         this.form.amount_formatted = this.form.amount;
         this.form.amount_value = this.form.amount;
        }
      },
      onImageChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;

        this.createImage( files[0], e.target.getAttribute("name") );

      },
      createImage(file, el_name) {
        let reader = new FileReader();
        let vm = this;

        reader.onload = (e) => {
            this.form.image = e.target.result; //the only thing that changes is the target
          }
        reader.readAsDataURL(file);
      }
    },
    mounted() {
      this.fix_amount_before_edit()
    }
  }
</script>
