<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="telephone_numbers_form" v-bind:list="list" 
      v-bind:customer_id="this.customer_id" v-show="!telephone_numbers_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="telephone_numbers_form" v-bind:list="list"
       v-bind:customer_id="this.customer_id" v-if="telephone_numbers_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../packs/form'

import telephone_numbers_form from './telephone_numbers/form'
import telephone_numbers_list from './telephone_numbers/list'

export default {
  props: ["customer_id"],
  data: function () {
    return {
      selected_element: null,
      list: null,
      telephone_numbers_form: new Form({
        id: null,
        number: null,
        name: null,
        is_whatsapp: false,
        is_mobile: false
        },
        this,
        `customers/${this.customer_id}`, '/telephone_numbers'
      )
    }
  },
  methods: {
    telephone_numbers_list_get: function() {
      this.$http.get(`customers/${this.customer_id}/telephone_numbers`).then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': telephone_numbers_form,
    'list-component': telephone_numbers_list
  },
  mounted() {
    this.telephone_numbers_list_get()
  }
}
</script>
