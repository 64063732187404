<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="models_form" v-bind:list="list" v-show="!models_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="models_form" v-bind:list="list" v-bind:device_types_list="device_types_list"
       v-bind:manufacturers_list="manufacturers_list" v-if="models_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../../packs/form'

import device_models_form from './models/form'
import device_models_list from './models/list'

export default {
  data: function () {
    return {
      selected_element: null,
      list: null,
      manufacturers_list: null,
      device_types_list: null,
      models_form: new Form({
        id: null,
        name: null,
        manufacturer_sku: null,
        manufacturer_id: null,
        device_type: null,
        photo: null,
        photo_thumb_url: null,
        manufacturer_logo_thumb_url: null
        },
        this,
        'inventory/devices', '/models'
      )
    }
  },
  methods: {
    manufacturer_list_get: function() {
      this.$http.get("inventory/devices/manufacturers").then(response => {
        this.manufacturers_list = response.body
        }, response => {
      })
    },
    model_list_get: function() {
      this.$http.get("inventory/devices/models").then(response => {
        this.list = response.body
        }, response => {
      })
    },
    model_device_type_list_get: function() {
      this.$http.get("inventory/devices/models/device_types").then(response => {
        this.device_types_list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': device_models_form,
    'list-component': device_models_list
  },
  mounted() {
    this.model_list_get()
    this.manufacturer_list_get()
    this.model_device_type_list_get()
  }
}
</script>
