<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agendar Nueva 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         Instalación
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_customer_id">Cliente</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">

                <multiselect v-model="form.customer_id" id="install_form_customer_id" :options="customers_list" 
                :custom-label="customers_menu_labels" :show-labels="false" style="max-width: 30em" placeholder="">
                </multiselect>
                  <span class="help is-danger" v-if="errors.has('customer_id')" v-text="errors.get('customer_id')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_radio_id">Radio</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">


                <multiselect v-model="form.radio_id" id="install_form_radio_id" :options="ptmp_clients_list" 
                :show-labels="false" style="max-width: 30em" placeholder="" openDirection="bottom" 
                :searchable="true" :internal-search="false" @search-change="ptmp_clients_autocomplete">

                  <template slot="singleLabel" slot-scope="props">
                    <img :src="ptmp_client_photo(props.option)" class="option__image" style="vertical-align: middle;">
                    <span class="option__desc">
                      <span class="option__title" style="margin-left: 2em;">
                        {{ ptmp_client_model_name( props.option) }}
                      </span>
                      <span class="option__title" style="margin-left: 2em;">
                        {{ ptmp_client_mac_address( props.option) }}
                      </span>
                    </span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <img :src="ptmp_client_photo(props.option)" class="option__image" style="vertical-align: middle;">
                    <span class="option__desc">
                      <span class="option__title" style="margin-left: 2em;">
                        {{ ptmp_client_model_name( props.option) }}
                      </span>
                      <span class="option__title" style="margin-left: 2em;">
                        {{ ptmp_client_mac_address( props.option) }}
                      </span>
                    </span>
                  </template>


                </multiselect>

                  <!--
                    <select v-model="form.radio_id"  id="install_form_radio_id" class="input" name="radio_id">
                      <optgroup v-for="manufacturer_group in devices_by_manufacturer_list" :label="manufacturer_group.manufacturer_name">
                      <option v-for="radio in manufacturer_group.devices" :value="radio.id" :key="radio.id">
                        {{ radio.name_for_menu }}
                      </option>
                      </optgroup>
                    </select>
                  -->
                  <span class="help is-danger" v-if="errors.has('radio_id')" v-text="errors.get('radio_id')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_router_id">Router</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                <multiselect v-model="form.router_id" id="install_form_router" :options="routers_list" 
                :show-labels="false" style="max-width: 30em" placeholder="" openDirection="bottom"
                :searchable="true" :internal-search="false" @search-change="routers_autocomplete">

                  <template slot="singleLabel" slot-scope="props">
                    <img :src="router_photo(props.option)" class="option__image" style="vertical-align: middle;" >
                    <span class="option__desc">
                      <span class="option__title" style="margin-left: 2em;">
                        {{ router_model_name( props.option) }}
                      </span>
                      <span class="option__title" style="margin-left: 2em;">
                        {{ router_mac_address( props.option) }}
                      </span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <img :src="router_photo(props.option)" class="option__image" style="vertical-align: middle;" >
                    <span class="option__desc">
                      <span class="option__title" style="margin-left: 2em;">
                        {{ router_model_name( props.option) }}
                      </span>
                      <span class="option__title" style="margin-left: 2em;">
                        {{ router_mac_address( props.option) }}
                      </span>
                    </span>
                  </template>
                </multiselect>
                  <!--
                    <select v-model="form.router_id"  id="install_form_router_id" class="input" name="router_id">
                      <optgroup v-for="manufacturer_group in devices_by_manufacturer_list" :label="manufacturer_group.manufacturer_name">
                      <option v-for="router in manufacturer_group.devices" :value="router.id" :key="router.id">
                        {{ router.name_for_menu }}
                      </option>
                      </optgroup>

                    </select>
                  -->
                  <span class="help is-danger" v-if="errors.has('router_id')" v-text="errors.get('router_id')"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_status">Status</label>
            </div>
            <div class="field-body">
              <div class="field">

                <div class="control has-icons-left">

                <multiselect v-model="form.status" id="install_form_status" :options="status_ids" :custom-label="status_labels" :searchable="false" :show-labels="false" style="max-width: 20em" placeholder="" >
                </multiselect>
                  <span class="help is-danger" v-if="errors.has('status')" v-text="errors.get('status')">
                  </span>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_scheduled_datetime">Fecha de Instalacion </label>
            </div>
            <div class="field-body">
              <datetime v-model="form.scheduled_datetime" type="datetime" input-class="input" v-bind:max-date="maxDate()" moment-locale="es" auto-continue>
              </datetime>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_ssid_name">Nombre de SSID </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.ssid_name" placeholder="INTERDIGITA.COM" id="install_form_ssid_name" class="input" name="ssid_name">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('ssid_name')" v-text="errors.get('ssid_name')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_ssid_password">Password de SSID</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.ssid_password" placeholder="****" id="install_form_ssid_password" class="input" name="ssid_password">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('ssid_password')" v-text="errors.get('ssid_password')">
                    </span>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>
      <section class="columns">
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="install_form_comments"> Comentarios</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <textarea v-model="form.comments" placeholder="Comments" id="install_form_comments" name="comments" class="textarea"></textarea>
                    <span class="help is-danger" v-if="errors.has('comments')" v-text="errors.get('comments')">
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import { Datetime } from 'vue-datetime';
  import moment from 'moment';
  //import 'vue-datetime/dist/vue-datetime.css'
  Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );

  export default {
    props: ['form', 'list', 'customers_data', 'status_list'],
    data: function () {
      return {
        errors: this.form.errors,

        devices_data: null,
        routers_list: [],
        ptmp_clients_list: []
      }
    },
    computed: {
      status_ids: function() {
        if (this.status_list != null) {
          return Object.keys(this.status_list) 
        }
        return [];
      },

      customers_list: function() {
        if (this.customers_data != null) {
          //return Object.keys(this.customers_list)
          return this.customers_data.map(customer => customer.id)
        }
        return [];
      },

      customers_menu_data: function() {
        let hash = {}
        if (this.customers_data != null) {
          this.customers_data.forEach( function(customer) {
            hash[customer.id] = customer.name
          })
        }
        return hash;
      },

      routers_data: function() {
        //solo agregame a mi mismo
        //setup_device_hash('router', install.router_id)
        let device_id = this.form.router_id
        return  this.setup_device_data('router', device_id)
      },

      ptmp_client_radio_data: function() {
        let device_id = this.form.radio_id
        return this.setup_device_data('ptmp_client_radio', device_id)
      }



      /* BONITO EJEMPLO DE GETTER Y SETTER COMPUTED
      scheduled_date: {
        get: function() {
				  return new Date( Date.parse( this.form.scheduled_date ) );
        },
        set: function(new_value) {
          this.form.scheduled_date = new_value
        }
      },
      scheduled_time: {
        get: function() {
				  return new Date( Date.parse( this.form.scheduled_time ) );
        },
        set: function(new_value) {
          this.form.scheduled_time = new_value
        }
      }
      */


    },
    methods: {

      customers_menu_labels: function(opt) {
        return this.customers_menu_data[opt]
      },

      status_labels: function(opt) {
        return this.status_list[opt]
      },

      devices_by_type_get() {
        this.$http.get("inventory/devices/installability").then(response => {
          this.devices_data = response.body
          this.ptmp_clients_list = Object.keys(this.ptmp_client_radio_data).map(Number)
          this.routers_list = Object.keys(this.routers_data).map(Number)
          }, response => {
        })
      },

      setup_device_data: function(dev_type, device_id) {
        device_id  = device_id || null
        //    return  ( device.device_type == 'router')  && ( device.available == true  || device.id = dev_id)  

        let hash = {};
        let data = null;
        if (this.devices_data != null) {
          if (device_id == null) {
            data =  this.devices_data.filter( function(device) {
              return  ( device.device_type == dev_type)  && ( device.available == true)
            })
          } else {
            data =  this.devices_data.filter( function(device) {
              return  ( device.device_type == dev_type)  && (  device.id == device_id || device.available == true)
            })
          }
          data.forEach( function(row) {
            hash[row.id] = row
          })
        }
        return hash;
      },

      ptmp_client_photo: function(opt) {
        if (this.ptmp_client_radio_data != null) {
          if (this.ptmp_client_radio_data[opt] != null) {
            return this.ptmp_client_radio_data[opt].device_model_photo_thumb_url
          }
        }
      },

      ptmp_client_mac_address: function(opt) {
        if (this.ptmp_client_radio_data != null) {
          if (this.ptmp_client_radio_data[opt] != null) {
            return this.ptmp_client_radio_data[opt].mac_address
          }
        }
      },

      ptmp_client_model_name: function(opt) {
        if (this.ptmp_client_radio_data != null) {
          if (this.ptmp_client_radio_data[opt] != null) {
            return this.ptmp_client_radio_data[opt].device_model_name
          }
        }
      },

      router_photo: function(opt) {
        if (this.routers_data != null) {
          if (this.routers_data[opt] != null) {
            return this.routers_data[opt].device_model_photo_thumb_url
          }
        }
      },

      router_mac_address: function(opt) {
        if (this.routers_data != null) {
          if (this.routers_data[opt] != null) {
            return this.routers_data[opt].mac_address
          }
        }
      },

      router_model_name: function(opt) {
        if (this.routers_data != null) {
          if (this.routers_data[opt] != null) {
            return this.routers_data[opt].device_model_name
          }
        }
      },

      routers_autocomplete: function(query) {

        let matching_devices = Object.filter(this.routers_data, function(r) {
          return ( r.mac_address.toLowerCase().indexOf(query.toLowerCase()) >= 0 )
        })
        this.routers_list = Object.keys(matching_devices).map( Number)

      },

      ptmp_clients_autocomplete: function(query) {

        let matching_devices = Object.filter(this.ptmp_client_radio_data, function(r) {
          return ( r.mac_address.toLowerCase().indexOf(query.toLowerCase()) >= 0 )
        })
        this.ptmp_clients_list = Object.keys(matching_devices).map(Number)

      },


      maxDate: function() {
			  return moment().add(15, 'days').toISOString()
        //tambien puedo meter el minDate 
      },

 			set_time_range: function() {
        //como deshabilitar todos los domingos?
				// estos valores bien podrian venir del API
				const min = new Date()
				min.setHours(8)
				min.setMinutes(0)
				const max = new Date()
				max.setHours(19)
				max.setMinutes(0)
				//this.minTime = min
				//this.maxTime = max
			}


    },
    components: {
      datetime: Datetime

    },
    mounted() {
      this.devices_by_type_get()  //pero si paso id recoge no mas los ids
    }
  }
</script>
