<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar nuevo 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         Modelo
        </h3>
      </div>
    </div>

    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="device_model_form_name">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.name" placeholder="T-3000" id="device_model_form_name" class="input" name="name">
                    <span class="icon is-small is-left">
                      <i class="fa fa-tag"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('name')" v-text="errors.get('name')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="device_model_form_manufacturer_sku">Número de Parte</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.manufacturer_sku" placeholder="T3000" id="device_model_form_manufacturer_sku" class="input" name="manufacturer_sku">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                  <span class="help is-danger" v-if="errors.has('manufacturer_sku')" v-text="errors.get('manufacturer_sku')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="device_model_form_manufacturer_id">Fabricante</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                     <multiselect v-model="form.manufacturer_id" id="install_form_manufacturer_id" :options="manufacturer_ids" 
                     :searchable="false" :show-labels="false" style="max-width: 20em" 
                     placeholder=""  openDirection="bottom">

                      <template slot="singleLabel" slot-scope="props">
                        <img class="" :src="manufacturer_logo(props.option)" style="vertical-align: middle;">
                        <span class="" style=" margin-left: 2em;">{{ manufacturer_label(props.option) }}</span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <img class="" :src="manufacturer_logo(props.option)" style="vertical-align: middle;">
                        <span class="" style="margin-left: 2em; vertical-align: middle;">{{ manufacturer_label(props.option) }}</span>
                      </template>

                     </multiselect>
                  <span class="help is-danger" v-if="errors.has('manufacturer_id')" v-text="errors.get('manufacturer_id')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="device_model_device_type">Tipo</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                     <multiselect v-model="form.device_type" id="device_model_device_type" :options="device_types_ids" :custom-label="device_types_labels" :searchable="false" :show-labels="false" style="max-width: 20em" placeholder="" :multiple="true" :taggable="true" openDirection="bottom" >
                     </multiselect>
                     <!--
                <multiselect v-model="form.customer_id" id="install_form_customer_id" :options="customers_ids" :custom-label="customers_labels" :show-labels="false" style="max-width: 30em" placeholder="">
:custom-label="status_labels" 
                     -->
                  <span class="help is-danger" v-if="errors.has('device_type')" v-text="errors.get('device_type')"></span>
                </div>
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="device_model_photo">Foto</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-upload name="photo" @change.native="onImageChange">
                  <a class="button is-small">
                    <b-icon icon="upload" pack="fa" size="is-small"></b-icon>
                    <span>Logo</span>
                  </a>
                </b-upload>
                <div class="col-md-3" v-if="form.photo_thumb_url">
                  <img :src="form.photo_thumb_url" class="img-responsive" >
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['form', 'list', 'manufacturers_list', 'device_types_list'],
    data: function () {
      return {
        errors: this.form.errors,
      }
    },
    computed: {
      device_types_ids: function() {
        if (this.device_types_list != null) {
          return Object.keys(this.device_types_list)
        }
        return [];
      },
      manufacturer_ids: function() {
        //all this select helpers could be refactored
        if (this.manufacturers_list != null) {
          return this.manufacturers_list.map(manufacturer => manufacturer.id)
        }
        return [];
      },
      manufacturers_hash: function() {
        let ary = [];
        if (this.manufacturers_list != null) {
          this.manufacturers_list.forEach( function(manufacturer) {
            //ary[manufacturer.id] = manufacturer.name
            ary[manufacturer.id] = manufacturer
          })
        }
        return ary;
      }
    },
    methods: {
      device_types_labels: function(opt) {
        return this.device_types_list[opt];
      },
      manufacturer_label: function(opt) {
        return this.manufacturers_hash[opt].name
      },
      manufacturer_logo: function(opt) {
        return this.manufacturers_hash[opt].logo_thumb_url
      },
      onImageChange(e) {
        //refactor into library
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage( files[0], e.target.getAttribute("name") );
      },
      createImage(file, el_name) {
        //refactor into library
        let reader = new FileReader();
        let vm = this;

        reader.onload = (e) => {
            this.form.photo = e.target.result;
          }
        reader.readAsDataURL(file);
      }
    }
  }
</script>
