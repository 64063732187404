<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Crear Nuevo 
          </template>
          <template v-if="form.mode == 'edit'">
          Editar 
          </template>
         cliente
        </h3>
      </div>
    </div>
    <customer-navigation v-bind:selected_tab="'customer_tab'" v-bind:customer_id="this.customer_id">
    </customer-navigation>

    <form>
      <section class="columns">
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="customer_name">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.name" placeholder="Nombre con apellidos" id="customer_name" class="input">
                  <span class="help is-danger" v-if="errors.has('name')" v-text="errors.get('name')"></span>
                  <span class="icon is-small is-left">
                    <i class="fa fa-male"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="customer_tax_id">RFC</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.tax_id" placeholder="XX-XX-XX-XX-XX" id="customer_tax_id" class="input">
                  <span class="help is-danger" v-if="errors.has('tax_id')" v-text="errors.get('tax_id')"></span>
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="customer_email">Correo Electrónico</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.email" v-validate="'required|email'" name="email" placeholder="mi_direccion@servidor.com" id="customer_email" class="input">
                  <span class="help is-danger" v-if="errors.first('email')" v-text="errors.first('email')"></span>

                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
<!--
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="telephone_number_form_number">Número Telefónico</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.primary_telephone_number.number" placeholder="998 123 4567" id="primary_telephone_number" class="input" name="primary_telephone_number.number">
                    <span class="icon is-small is-left">
                      <i class="fa fa-phone"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('primary_telephone_number.number')" v-text="errors.get('primary_telephone_number.number')">
                    </span>
                </div>

                <b-switch size="is-small is-info" v-model="form.primary_telephone_number.is_mobile">
                <b-icon pack="fa" icon="mobile" size="small"></b-icon>
                  Es Celular
                </b-switch>
                <b-switch size="is-small" v-model="form.primary_telephone_number.is_whatsapp">
                <b-icon pack="fa" icon="whatsapp" size="small"></b-icon>
                  Con WhatsApp
                </b-switch>

              </div>
            </div>
          </div>


-->

        </div>
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="customer_proof_of_identity_url">Identificacion</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-upload name="proof_of_identity" @change.native="onImageChange">
                  <a class="button is-small">
                    <b-icon icon="upload" pack="fa" size="is-small"></b-icon>
                    <span>Identificacion</span>
                  </a>
                </b-upload>
                <div class="col-md-3" v-if="form.proof_of_identity_url">
                  <img :src="form.proof_of_identity_url" class="img-responsive" width=300 height=200>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="customer_proof_of_address_url">Comprobante de Domicilio</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-upload name="proof_of_address" @change.native="onImageChange">
                  <a class="button is-small">
                    <b-icon icon="upload" pack="fa" size="is-small"></b-icon>
                    <span>Comprobante de Domicilio</span>
                  </a>
                </b-upload>
                <div class="col-md-3" v-if="form.proof_of_address_url">
                  <img :src="form.proof_of_address_url" class="img-responsive" width=300 height=200>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="customers_list()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="before_submit()" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import customer_navigation from './navigation'
  import Form from '../../../packs/form'
  export default {
    props: ['customer_id', 'form_mode'],
    $_veeValidate: {
      validator: 'new'
    },
    data: function () {
      return {
        list: null,
        form: new Form({
          id: null,
          name: null,
          email: null,
          tax_id: null,
          proof_of_identity: null,
          proof_of_address: null,
          proof_of_identity_url: null,
          proof_of_address_url: null,
          primary_user: {
            id: null,
            username: null,
            password: null,
            password_confirmation: null
          },
          primary_telephone_number: { id: null, number: null,
            is_whatsapp: false,
            is_mobile: false
          }
        },
        this,
        'customers', ''
        )
        
      }
    },
    methods: {

      onImageChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage( files[0], e.target.getAttribute("name") );
      },

      createImage(file, el_name) {
        //console.log(file.name)
        //console.log(el_name)
        let reader = new FileReader();
        let vm = this;

        reader.onload = (e) => {
          if (el_name == 'proof_of_identity')  {
            this.form.proof_of_identity = e.target.result;
          } else if (el_name == 'proof_of_address')  {
            this.form.proof_of_address = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      },

      setup_form() {
        if (this.customer_id != null) {
          this.form.get(this.customer_id);
        }
        this.form.mode = this.form_mode
      },

/*
      before_submit() {
        this.form.originalData['primary_user_attributes'] = null;

//        delete this.form.primary_user.roles_list;
        this.form.primary_user_attributes = this.form.primary_user;
        delete this.form.primary_user;

        this.form.originalData['telephone_numbers_attributes'] = null;
        this.form.telephone_numbers_attributes = this.form.primary_telephone_number;

        delete this.form.primary_telephone_number;
      },
*/
    before_submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.form.submit(this.list)
          this.$router.push("/admin/customers");
          this.$router.go();
          //authentication.register(this, this.registration );
        }
      });
		},

      customers_list: function() {
        this.form.cancel();
        this.$router.push("/admin/customers");
        this.$router.go();
      }

    },
    components: {
      'customer-navigation': customer_navigation
    },
    beforeMount() {
      this.setup_form()
    },
    created() {
      this.$validator.localize('es');
    },
  }
</script>

