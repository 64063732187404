<template>
    <nav class="navbar is-spaced is-dark">

      <div class="navbar-brand">
        <div id="navbar-burger-id" class="navbar-burger burger" data-target="navMenu" v-on:click="toggleBurger">
          <span></span>
          <span></span>
          <span></span>
        </div>
<!--
        <a class="navbar-item logo" href="/app/admin">
          <img src="/images/logo-interdigita-small.png">
        </a>
-->
        <router-link :to="{ name: 'admin' }" class="navbar-item">
          <img src="/images/logo-interdigita-small.png">
        </router-link>

        <logged-status v-if="$mq === 'small'"> </logged-status>

      </div>

      <div class="navbar-menu" id="navMenu" v-bind:class="{ 'is-active': burger_active }" v-on:click="hideBurger">
        <div class="navbar-start">

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
            <span class="icon  is-left">
               <i class="fa fa-wifi"></i>
            </span>
              INTERNET
            </a>

            <div class="navbar-dropdown">
              <router-link to="/admin/internet/service_plans" class="navbar-item"> Planes </router-link>
              <router-link to="/admin/internet/installs" class="navbar-item"> Instalaciones </router-link>
              <router-link to="/admin/internet/subscriptions" class="navbar-item"> Subscripciones </router-link>
            </div>
          </div>

          <div class="navbar-item">
            <a class="navbar-item">
            <span class="icon is-left">
               <i class="fa fa-phone"></i>
            </span>
              TELEFONIA
            </a>

          <!--
            <div class="navbar-dropdown">
              <router-link to="#" class="navbar-item"> Carriers </router-link>
              <router-link to="#" class="navbar-item"> Troncales </router-link>
              <router-link to="#" class="navbar-item"> Numeros Inbound (DID) </router-link>
              <router-link to="#" class="navbar-item"> Rutas </router-link>
              <router-link to="#" class="navbar-item"> Listas de Precio </router-link>
            </div>
          -->
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
            <!-- 
              <a class="navbar-link" href="/admin/customers">
            -->
              <span class="icon is-left">
                 <i class="fa fa-users"></i>
              </span>
              CLIENTES
            </a>
            <div class="navbar-dropdown">
              <router-link :to="{ name: 'customers_list'}" class="navbar-item"> Clientes </router-link>
              <router-link :to="{ name: 'payments'}" class="navbar-item"> Vencimientos </router-link>
              <!--
              <router-link to="/admin/customers" class="navbar-item"> Clientes </router-link>
              <router-link to="/admin/customers/payments" class="navbar-item"> Pagos </router-link>
              -->
            </div>
            <!--
              <router-link to="#" class="navbar-item"> Facturacion </router-link>
              <router-link to="#" class="navbar-item"> Vencimientos </router-link>
            -->
          </div>


            <!--
          <div class="navbar-item">
            <a class="navbar-item">
              <span class="icon is-left">
                 <i class="fa fa-money"></i>
              </span>
              SOPORTE
            </a>
            <div class="navbar-dropdown ">
              <router-link to="#" class="navbar-item"> Pagos </router-link>
              <router-link to="#" class="navbar-item"> Vencimientos </router-link>
              <router-link to="#" class="navbar-item"> Facturacion </router-link>
            </div>
          </div>
            -->


          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
            <span class="icon  is-left">
               <i class="fa fa-cog"></i>
            </span>
              ADMINISTRACION
            </a>

            <div class="navbar-dropdown">
              <router-link to="/admin/staff/users" class="navbar-item"> STAFF </router-link>
               <hr class="navbar-divider">

              <!-- INVENTARIO -->
              <!-- dispositivos -->
              <router-link to="/admin/inventory/devices" class="navbar-item"> Dispositivos </router-link>
              <router-link to="/admin/inventory/devices/manufacturers" class="navbar-item"> Fabricantes </router-link>
              <router-link to="/admin/inventory/devices/models" class="navbar-item"> Modelos </router-link>
               <hr class="navbar-divider">

              <!-- redes -->
              <router-link to="/admin/inventory/network_addresses" class="navbar-item"> Redes </router-link>
              <router-link to="/admin/inventory/network_addresses/groups" class="navbar-item"> Grupos de redes </router-link>
            </div>
          </div>

        </div>

        <div class="navbar-end">
        </div>
      </div>
      <logged-status v-if="$mq === 'desktop'"> </logged-status>
    </nav>
</template>

<script>
import LoggedStatus from '../LoggedStatus'
export default {
  data: function () {
    return {
      burger_active: false
    }
  },
  methods: {

    hideBurger: function() {
      this.burger_active = false;
    },
    toggleBurger: function() {
      this.burger_active = !this.burger_active;
    }
  },
  components: {
   'logged-status': LoggedStatus
  }
}
</script>
