<template>
  <div class='register-form columns'>
    <div class="column is-three-fifths is-offset-one-fifth">
      <section class="hero is-info is-bold">
          <h1 class="title"><i class="fa fa-user-circle-o fa-3x" ></i>Regístrese</h1>
          <h3 class="subtitle"> Utilice este formulario para tener acceso a nuestros servicios</h3>
      </section>
      <div>
        <form class="">
          <fieldset>
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="registration_name">Nombre</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input v-model="registration.name" placeholder="Nombre con apellidos" id="registration_name" class="input">
                    <span class="icon is-small is-left">
                      <i class="fa fa-male"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="registration_email">Correo Electrónico</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input v-model="registration.primary_user.email" placeholder="mi_direccion@servidor.com" id="registration_email" class="input">
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="registration_password">Contraseña</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input v-model="registration.primary_user.password" type="password" id="registration_password" class="input">
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="registration_password_confirmation">Confirmación</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input v-model="registration.primary_user.password_confirmation" type="password" id="registration_password_confirmation" class="input">
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="registration_telephone_number_number">Número Telefónico</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input v-model="registration.telephone_number.number" type="tel" placeholder="9981234000" id="registration_telephone_number_number" class="input">
                    <span class="icon is-small is-left">
                      <i class="fa fa-phone"></i>
                    </span>
                    <b-switch size="is-small is-info" v-model="registration.telephone_number.is_mobile"> Es Celular </b-switch> 
                    <b-switch size="is-small" v-model="registration.telephone_number.is_whatsapp"> Con WhatsApp </b-switch> 
                  </div>
                </div>
              </div>
            </div>

          <button class="button is-link" v-on:click="register">Regístrese</button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authentication from '../packs/authentication'
export default {
  data: function () {
    return {
      registration: {
          name: null,
          telephone_number: {
            number: null,
            is_whatsapp: false,
            is_mobile: false
          },
          primary_user: {
            email: null,
            password: null,
            password_confirmation: null
          }
      }
    }
  },

  methods: {
    register: function(event) {
      event.preventDefault();
      authentication.register(this, {
        customer: {
          name: this.registration.name,
          telephone_numbers_attributes: [
            {
              number: this.registration.telephone_number.number,
              is_whatsapp: this.registration.telephone_number.is_whatsapp,
              is_mobile: this.registration.telephone_number.is_mobile
            }
          ],
          primary_user_attributes: {
            email: this.registration.primary_user.email,
            password: this.registration.primary_user.password,
            password_confirmation: this.registration.primary_user.password_confirmation
          }
        }
      })
    }
  }
}
</script>
