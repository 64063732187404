<template>
  <div class="container is-fluid">
    <!-- component -->
    <!--
          <td>  payment.customer_name  </td>
    -->
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Control de pagos </h3>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Importe </th>
          <th> Fecha  </th>
          <th> Confirmacion </th>
          <th> Comprobante </th>
          <th> Referencia </th>
        </tr>
      </thead>
      <tbody v-for="payment in list">
        <tr @click.prevent="select_element(payment.id)" v-bind:class="{ 'is-selected': isElementSelected(payment.id) }">
          <td> {{ payment.amount_formatted }} </td>
          <td class="subscription_start_date">
            <span>
              {{ payment.date | format_day }}
            </span>
            <span>
              {{ payment.date | format_month }}
            </span>
            <span>
              {{ payment.date | format_year }}
            </span>
          </td>
          <td> {{ (payment.confirmed == true) ? 'SI' : 'NO' }} </td>
          <td> <img :src="payment.image_thumb_url" class="img-responsive" v-if="payment.image_thumb_url"> </td>
          <td> {{ payment.reference }} </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <span>
            TOTAL RECIBIDO
            </span>
            $
            <span class="service_plan_amount">
            {{ total_payments.toFixed(2).toString() | format_amount}}
            </span>
            <span class="service_plan_cents">
            {{ total_payments.toFixed(2).toString()  | format_cents }}
            </span>
            <span class="service_plan_currency">
             MXN 
            </span>
          </td>
         <td colspan=4></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="submit_action('delete')" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
        <button class="button" @click.prevent="submit_action('edit')" v-if="selected_element">
        <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
          <span>Editar</span>
        </button>
        </div>
        <div class="level-item">
        <button class="button is-success" @click.prevent="form.new">
          <span>Nuevo</span>
        </button>
        </div>
      </div>
    </div>
  <!-- component -->
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null
    }
  },
  computed: {
    total_payments: function() {
      if (this.list == null) {
        return 0;
      }
      return this.list.reduce(function (total, payment) {
        return total + Number(payment.amount_value);
      }, 0);
    }
    
  },
  methods: {
    select_element: function(element_id) {
      this.selected_element = ( this.selected_element == element_id) ? null : element_id
    },
    isElementSelected: function(element_id) {
      return (this.selected_element == element_id)
    },
    submit_action: function(action) {
      let customer_id = Object.values(this.list).find(payment => payment.id == this.selected_element)["customer_id"]
      this.form.uri = `customers/${customer_id}/payments`
      if (action == 'edit') {
        this.form.edit(this.selected_element, this.list)
      } else if (action == 'delete') {
        this.confirm_delete(this.selected_element)
      }
    },
    confirm_delete: function(element_id) {
      this.$dialog.confirm({
        message: "Desea eliminar este pago?",
        onConfirm: () => {
          this.form.delete(element_id, this.list)
        }
      })
    }
  },
  filters: {
    format_day: function (value) {
      if (!value) return ''
      return moment(value).format('D');
    },
    format_month: function (value) {
      if (!value) return ''
      let m = moment(value).format('MMM');
      return (m.charAt(0).toUpperCase() + m.slice(1)).slice(0, -1);
    },
    format_year: function (value) {
      if (!value) return ''
      return moment(value).format('YY');
    },
    format_amount: function (value) {
      if (!value) return ''
      return value.substr(0, value.length - 3);
    },
    format_cents: function (value) {
      if (!value) return ''
      return value.substr(value.length - 2);
    }
  }
}
</script>
