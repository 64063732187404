<template>
    <nav class="navbar is-fixed-top">
      <div class="navbar-brand">
        <img src="/images/logo.png"  class="navbar-item">
      </div>
      <div class="navbar-menu">
        <div class="navbar-start">
        </div>
        <div class="navbar-end">
          <a href="#info" class="navbar-item">Nosotros</a>
          <a href="#features" class="navbar-item">Residencial</a>
          <a href="#pricing" class="navbar-item">Negocios</a>
          <a href="#footer" class="navbar-item">Ayuda</a>
          <div id="logged-status-widget" class="navbar-item">
            <logged-status>
            </logged-status>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>
import LoggedStatus from './LoggedStatus'
export default {
  data: function () {
    return {
    }
  },
  components: {
   'logged-status': LoggedStatus
  }
}
</script>
