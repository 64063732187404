<template>


  <div>
    <h2> PAGINA DE CLIENTES </h2>
    <div v-if="$auth.ready()">
      <p> Gracias por registrarse, <strong> {{ $auth.user().name }} </strong> </p>
      <ul> 
        <li> Su nombre de usuario es <strong>{{ $auth.user().username }} </strong> </li>
        <li> su contraseña es <strong> {{ this.secret() }} </strong> </li>
      </ul>
      <!--
        <span> ( {{ $auth.watch.authenticated }}  ) </span>
        <span> ( {{ $auth.watch.data }}  ) </span>
        <span> ( {{ $auth.token() }}  ) </span>
      -->
    </div>
    <div v-if="!$auth.ready()">
      Loading...
    </div>
  </div>


</template>

<script>
import authentication from '../packs/authentication'


export default {
  data: function () {
    return {

    }
  },

  methods: {
    secret: function() {
      return authentication.user.secret;
    }

  }
}


</script>

/*
INFO ES SECURENO PUEDO ENTRAR A LOGIN SI YA ESTOY LOGEADO
*/
<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
