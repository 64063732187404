<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="staff_form" v-bind:list="list" v-show="!staff_form.is_active" >
      </list-component>
    </transition>
    <transition name="fade">
    <form-component v-bind:form="staff_form" v-bind:list="list"
     v-bind:user_roles_list="user_roles_list"v-if="staff_form.is_active">
      </form-component>
    </transition>
  </div>
</template>
<script>
import authentication from '../../packs/authentication'
import Errors from '../../packs/errors'
import Form from '../../packs/form'

import staff_form from './staff/form'
import staff_list from './staff/list'
export default {
  data: function () {
    return {
      user_roles_list: null,
      list: null,
      selected_element: null,
      staff_form: new Form({
        id: null,
        name: null,
        username: null,
        password: null,
        password_confirmation: null,
        roles_list: null
        },
        this,
        'staff/users', '/'
      )
    }
  },

  methods: {
   users_list_get() {
        this.$http.get("staff/users").then(response => {
        this.list = response.body
        }, response => {
      })
    },
   users_roles_list_get() {
      this.$http.get("staff/users/roles_list").then(response => {
        this.user_roles_list = response.body
        }, response => {
      })

    }
  },
  components: {
    'form-component': staff_form,
    'list-component': staff_list
  },
  mounted() {
   this.users_list_get()
   this.users_roles_list_get()
  }
}
</script>
