<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Fabricantes de dispositivos </h3>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <!--
      <thead>
        <tr>
          <th> Nombre </th>
          <th colspan=2> &nbsp; </th>
        </tr>
      </thead>
              width=100 height=80>
      -->
      <tbody v-for="manufacturer in list">
        <tr @click.prevent="select_element(manufacturer.id)" v-bind:class="{ 'is-selected': isElementSelected(manufacturer.id) }">
          <td> 
            <div class="col-md-3" v-if="manufacturer.logo_url">
              <img :src="manufacturer.logo_thumb_url" class="img-responsive">
            </div>
            {{ manufacturer.name }} 
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-success" @click.prevent="form.new">
            <span>Agregar Fabricante</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null
    }
  },

  methods: {
    select_element: function(manufacturer_id) {
     this.selected_element = ( this.selected_element == manufacturer_id) ? null : manufacturer_id
    },
    isElementSelected: function(manufacturer_id) {
     return (this.selected_element == manufacturer_id)
    },

    confirm_delete: function(manufacturer_id) {
      this.$dialog.confirm({
        message: "Desea eliminar ese fabricante?",
        onConfirm: () => {
          this.form.delete(manufacturer_id, this.list)
        }
      })
    }
  }
}
</script>
