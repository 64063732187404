import Errors from './errors'
import authentication from './authentication'

export default class Form {

  constructor(data, context, base_resource, resource){
    this.originalData = data;
    this.uri = this.build_uri(base_resource, resource);
    this.http = context.$http;

    for (let field in data) {
      this[field] = data[field];
    }

    this.errors = new Errors();
    this.is_active = false;
    this.mode = 'new';

  }

  build_uri(base_resource, resource) {
    return base_resource + resource;
  }


  data() {
    let data = {};

    for (let property in this.originalData) {
      data[property] = this[property];
    }

    return data;
  }


  render(mode){
    if ( mode == 'new' || mode == 'edit' ) {
      this.mode = mode;
      this.is_active = true;
    }
  }

  new(){
    this.render('new');
  }

  get(item_id){
    this.http.get(
      this.uri_for_selected_element(item_id),
      { }
    ).then(response => {
      this.element = response.body;
      for (let field in this.originalData) {
        this[field] = this.element[field];
      }
      }, response => {
        this.errors.record(response.body.errors);
      })
  }

  //why edit requires a list? 

  edit(item_id, list){
    this.render('edit');
    //console.log(this.uri)
    this.element = list.filter(item => item.id === item_id)[0];  //could have used find
    for (let field in this.originalData) {
      this[field] = this.element[field];
    }
  }

  reset() {

    for (let field in this.originalData) {
      this[field] = this.originalData[field];
    }

    this.errors.clear();

  }


  close() {
    this.is_active = false;
  }

  cancel() {
    this.close();
    this.reset();
  }

  finish() {
    this.cancel();
  }

  uri_for_selected_element(element_id) {
    return this.uri + '/' + element_id
  }

  submit(list) {
    if (this.mode == 'new') {
      this.post(list);
    } else if (this.mode == 'edit') {
      if (list == null) {
        this.simple_put(this.id);
      } else {
        this.put(this.id, list);
      }
    }
  }



  post(list) {
    this.http.post(
      this.uri,
      this.data(),
      { }
    ).then(response => {
        list.push(response.body);
        this.finish();
      }, response => {
        this.errors.record(response.body.errors);
      })
  }


  put(item_id, list) {
    this.http.put(
      this.uri_for_selected_element(item_id),
      this.data(),
      { }
    ).then(response => {
        list.forEach(function(item, i) {
          if (item['id'] === item_id) {

            for (let field in this.originalData) {
              list[i][field] = this[field];
            }

          }
        }.bind(this))
/*
      this.element = response.body;
      for (let field in this.originalData) {
        this[field] = this.element[field];
      }
*/
        this.finish();
      }, response => {
        this.errors.record(response.body.errors);
      })
  }

  simple_put(item_id) {
    this.http.put(
      this.uri_for_selected_element(item_id),
      this.data(),
      { }
    ).then(response => {
      this.element = response.body;
      for (let field in this.originalData) {
        this[field] = this.element[field];
      }
    }, response => {
      this.errors.record(response.body.errors);
    })
  }

  delete_reload(item_id, list) {
    this.delete_reload_uri =  this.uri_for_selected_element(item_id) + '?return_all_data=true'
    this.http.delete(
      this.delete_reload_uri,
      { }
    ).then(response => {
      for (var i = list.length -1; i >= 0; i--) {
          list.splice(i, 1);
      }
      var new_data = response.body
      for (var i = new_data.length -1; i >= 0; i--) {
          list.push(new_data[i]);
      }
    }, response => {
      this.errors.record(response.body.errors);
    })
  }

  delete(item_id, list) {
    this.http.delete(
      this.uri_for_selected_element(item_id),
      { }
    ).then(response => {


      for (var i = list.length -1; i >= 0; i--) {
        if (list[i]['id'] === item_id) {
          list.splice(i, 1);
          break;
        }
      }

      }, response => {
        this.errors.record(response.body.errors);
    })
  }

}
