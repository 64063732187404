<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar nuevo 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
          número telefónico
        </h3>
      </div>
    </div>
    <customer-navigation v-bind:selected_tab="'telephone_numbers_tab'" v-bind:customer_id="customer_id">
    </customer-navigation>


    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">

        <div class="column">

          <div class="field is-horizontal">

            <div class="field-label">
              <label class="label" for="telephone_number_form_number">Número Telefónico</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.number" placeholder="998 123 4567" id="telephone_number_form_number" class="input" name="number">
                    <span class="icon is-small is-left">
                      <i class="fa fa-phone"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('number')" v-text="errors.get('number')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label" for="telephone_number_form_name">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.name" placeholder="Casa" id="telephone_number_form_name" class="input" name="name">
                    <span class="icon is-small is-left">
                      <i class="fa fa-phone"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('name')" v-text="errors.get('name')">
                    </span>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <b-switch size="is-small is-info" v-model="form.is_mobile">
                <b-icon pack="fa" icon="mobile" size="small"></b-icon>
                  Es Celular
                </b-switch>
                <b-switch size="is-small" v-model="form.is_whatsapp">
                <b-icon pack="fa" icon="whatsapp" size="small"></b-icon>
                  Con WhatsApp
                </b-switch>
              </div>
            </div>
          </div>

        </div>
      </section>

      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import customer_navigation from './../navigation'
  export default {
    props: ['form', 'list', 'customer_id'],
    data: function () {
      return {
        errors: this.form.errors
      }
    },
    components: {
      'customer-navigation': customer_navigation
    }
  }
</script>
