<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="network_address_groups_form" v-bind:list="list" v-show="!network_address_groups_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="network_address_groups_form" v-bind:list="list" v-if="network_address_groups_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../../packs/form'

import network_address_groups_form from './groups/form'
import network_address_groups_list from './groups/list'

export default {
  data: function () {
    return {
      list: null,
      network_address_groups_form: new Form({
        id: null,
        name: null
        },
        this,
        'inventory/network_address_groups', ''
      )
    }
  },
  methods: {
    network_address_groups_list_get: function() {
      this.$http.get("inventory/network_address_groups").then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': network_address_groups_form,
    'list-component': network_address_groups_list
  },
  mounted() {
    this.network_address_groups_list_get()
  }
}
</script>

