<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Modelo de dispositivo </h3>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Nombre </th>
          <th> Número de Parte </th>
          <th> Fabricante </th>
          <th> &nbsp; </th>
        </tr>
      </thead>
      <tbody v-for="device_model in list">
        <tr @click.prevent="select_element(device_model.id)" v-bind:class="{ 'is-selected': isElementSelected(device_model.id) }">
          <td> {{ device_model.name }} </td>
          <td> {{ device_model.manufacturer_sku }} </td>
          <td>
            <img :src="device_model.manufacturer_logo_thumb_url" class="img-responsive" v-if="device_model.manufacturer_logo_thumb_url" :alt="device_model.manufacturer_name"> 
          </td>
          <td>
            <img :src="device_model.photo_thumb_url" class="img-responsive" v-if="device_model.photo_thumb_url">
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-success" @click.prevent="form.new">
            <span>Agregar Modelo</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null
    }
  },
  methods: {
    select_element: function(model_id) {
     this.selected_element = ( this.selected_element == model_id) ? null : model_id
    },
    isElementSelected: function(model_id) {
     return (this.selected_element == model_id)
    },

    confirm_delete: function(model_id) {
      this.$dialog.confirm({
        message: "Desea eliminar este modelo?",
        onConfirm: () => {
          this.form.delete(model_id, this.list)
        }
      })
    }
  }
}
</script>
