<template>
  <div>
    <div v-if="$auth.ready()">
      <navigation></navigation>
      <router-view></router-view>
    </div>
    <div v-if="!$auth.ready()">
      Loading...
    </div>
  </div>
</template>

<script>
import authentication from '../../packs/authentication'
import Navigation from './Navigation'

export default {
  data: function () {
    return {
    }
  },
  methods: {
  },
  components: {
   'navigation': Navigation
  }
}
</script>
