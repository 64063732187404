<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="devices_form" v-bind:list="list" v-show="!devices_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="devices_form" v-bind:list="list"
       v-bind:device_models_list="device_models_list" v-if="devices_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../packs/form'

import devices_form from './devices/form'
import devices_list from './devices/list'

export default {
  data: function () {
    return {
      selected_element: null,
      list: null,
      device_models_list: null,
      devices_form: new Form({
        id: null,
        mac_address: null,
        serial_number: null,
        available: null,
        device_type: null,
        device_model_id: null
        },
        this,
        'inventory/devices', '/'
      )
    }
  },
  methods: {
    device_models_list_get: function() {
      this.$http.get("inventory/devices/models").then(response => {
        this.device_models_list = response.body
        }, response => {
      })
    },
    device_list_get: function() {
      this.$http.get("inventory/devices").then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': devices_form,
    'list-component': devices_list
  },
  mounted() {
    this.device_list_get()
    this.device_models_list_get()
  }
}
</script>
