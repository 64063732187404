<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="manufacturers_form" v-bind:list="list" v-show="!manufacturers_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="manufacturers_form" v-bind:list="list"
     v-if="manufacturers_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../../packs/form'

import device_manufacturers_form from './manufacturers/form'
import device_manufacturers_list from './manufacturers/list'

export default {
  data: function () {
    return {
      list: null,
      selected_element: null,
      manufacturers_form: new Form({
        id: null,
        name: null,
        logo: null
        },
        this,
        'inventory/devices', '/manufacturers'
      )
    }
  },
  methods: {
    manufacturer_list_get: function() {
      this.$http.get("inventory/devices/manufacturers").then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': device_manufacturers_form,
    'list-component': device_manufacturers_list
  },
  mounted() {
    this.manufacturer_list_get()
  }
}
</script>
