<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Iniciar 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         Subscripción
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="subscription_form_customer_name">Cliente</label>
            </div>
            <div class="field-body">
              <div class="field">
                <!-- meter el multiselect en disabled ? -->
                <div class="control has-icons-left">
                        {{ form.customer_name }}
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="subscription_form_service_plan_id">Plan de Servicio</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <div class="select">
                    <select v-model="form.service_plan_id"  id="subscription_form_service_plan_id" class="input" name="service_plan_id">
                      <option v-for="service_plan in service_plans_list" :value="service_plan.id" :key="service_plan.id">
                        {{ service_plan.name }}
                      </option>
                    </select>
                  </div>
                    <span class="icon is-small is-left">
                      <i class="fa fa-briefcase"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('service_plan_id')" v-text="errors.get('service_plan_id')">
                    </span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="subscription_start_date">Fecha de Inicio </label>
            </div>
            <div class="field-body">
              <datetime v-model="form.start_date" type="date" input-class="input" moment-locale="es" auto-continue>
              </datetime>
            </div>
          </div>

          <!--
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="subscription_form_install_id">Install ID</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.install_id" placeholder="100" id="subscription_form_install_id" class="input" name="install_id">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('install_id')" v-text="errors.get('install_id')">
                    </span>
                </div>
              </div>
            </div>
          </div>
          -->

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="subscription_form_status">Status</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <div class="select">
                    <select v-model="form.status"  id="subscription_form_status" class="input" name="status">
                      <option v-for="key,val in status_list" :value="val" :key="val">
                        {{ key }}
                      </option>
                    </select>
                  </div>
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('status')" v-text="errors.get('status')">
                    </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list);" :disabled="errors.any()">

            <template v-if="form.mode == 'new'">
              Crear
            </template>
            <template v-if="form.mode == 'edit'">
              Guardar Cambios
            </template>
          </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import { Datetime } from 'vue-datetime';
  import moment from 'moment';
  export default {
    props: ['form', 'list', 'service_plans_list', 'status_list'],
    data: function () {
      return {
        errors: this.form.errors
      }
    },
    components: {
      datetime: Datetime
    }

  }
</script>
