<template>
  <div>
    <transition name="fade">
      <list-component v-bind:form="subscription_form" v-bind:list="list" v-show="!subscription_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
    <form-component v-bind:form="subscription_form" v-bind:list="list"  v-bind:service_plans_list="service_plans_list" v-bind:status_list="status_list" v-if="subscription_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import authentication from '../../../packs/authentication'
import Errors from '../../../packs/errors'
import Form from '../../../packs/form'

import subscription_form from './subscriptions/form'
import subscription_list from './subscriptions/list'

export default {
  data: function () {
    return {
      list: null,
      selected_element: null,
      status_list: null,
      service_plans_list: null,
      subscription_form: new Form({
        id: null,
        customer_name: null,
        install_id: null,
        service_plan_id: null,
        service_plan_name: null,
        start_date: new Date().toISOString(),
        cut_off_day: null,
        alternate_cut_off_day: null,
        status: null
        },
        this,
        'internet/subscriptions', '/'
      )
    }
  },

  methods: {
    service_plans_list_get() {
      this.$http.get("internet/service_plans/").then(response => {
        this.service_plans_list = response.body
        }, response => {
      })
    },
    subscriptions_list_get() {
      this.$http.get("internet/subscriptions/").then(response => {
        this.list = response.body
        }, response => {
      })
    },
    subscription_status_list_get() {
      this.$http.get("internet/subscriptions/statuses").then(response => {
        this.status_list = response.body
        }, response => {
      })
    }
  },
  components: {
    'form-component': subscription_form,
    'list-component': subscription_list
  },
  mounted() {
    this.subscriptions_list_get()
    this.subscription_status_list_get()
    this.service_plans_list_get()
  }
}
</script>
