<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar Nuevo 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         Fabricante
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="manufacturer_name">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.name" placeholder="XYZ Inventos" id="manufacturer_name" class="input" name="name">
                    <span class="icon is-small is-left">
                      <i class="fa fa-briefcase"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('name')" v-text="errors.get('name')">
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="manufacturer_logo">Logo </label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-upload name="logo" @change.native="onImageChange">
                  <a class="button is-small">
                    <b-icon icon="upload" pack="fa" size="is-small"></b-icon>
                    <span>Logo</span>
                  </a>
                </b-upload>
                <div class="col-md-3" v-if="form.logo_thumb_url">
                  <img :src="form.logo_thumb_url" class="img-responsive" width=300 height=200>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['form', 'list'],
    data: function () {
      return {
        errors: this.form.errors
      }
    },
    methods: {
      onImageChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage( files[0], e.target.getAttribute("name") );
      },
      createImage(file, el_name) {
        let reader = new FileReader();
        let vm = this;

        reader.onload = (e) => {
            this.form.logo = e.target.result;
          }
        reader.readAsDataURL(file);
      }
    }
  }
</script>
