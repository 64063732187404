<template>
  <div class="tabs is-boxed">
    <ul>
      <li v-bind:class="[ ( selected_tab == 'customer_tab') ? 'is-active' : ''   ]">

        <router-link :to="{ name: 'customer', params: { customer_id: customer_id }}">
          <span class="icon is-small"><i class="fa fa-user-o"></i></span>
          <span>Información Básica </span>
        </router-link>
      </li>
      <li v-bind:class="[ ( selected_tab == 'addresses_tab') ? 'is-active' : ''   ]">

        <router-link :to="{ name: 'customer_addresses', params: { customer_id: customer_id }}">
          <span class="icon is-small"><i class="fa fa-address-book-o"></i></span>
          <span>Direcciones</span>
        </router-link>
      </li>
      <li v-bind:class="[ ( selected_tab == 'telephone_numbers_tab') ? 'is-active' : ''   ]">
        <router-link :to="{ name: 'customer_telephone_numbers', params: { customer_id: customer_id }}">
          <span class="icon is-small"><i class="fa fa-phone"></i></span>
          <span> Teléfonos </span>
        </router-link>
      </li>
      <li v-bind:class="[ ( selected_tab == 'access_tab') ? 'is-active' : ''   ]">
        <router-link :to="{ name: 'customer_access_control', params: { customer_id: customer_id }}">
          <span class="icon is-small"><i class="fa fa-key"></i></span>
          <span> Acceso </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    props: ['selected_tab', 'customer_id'],
    data: function () {
      return {
      }
    }
  }
</script>

