import {router} from './interdigita'
import jwt_decode from 'jwt-decode'
import {interdigita_api} from './api.js'


export default {
// esto quiza ya no... a menos que quiera yo accesores mas practicos a los valores de $auth.user()
  user: {
    roles: [],
    username: null,
    name: null,
    secret: null
  },

  /*
   localStorage.setItem("token", this.data.jwt)
   */

  register(context, registration_data) {
    let local = this
    context.$auth.register({
      body: registration_data,
      autoLogin: false,
      rememberMe: false,
      fetchUser: false,
      error: function(res) {
        Event.$emit('registration-error-event', 'Error de registro');
        context.parse_server_side_errors(res.body.errors);
      },
      success: function(res) {
        local.user.secret = res.body.password;
        context.$auth.login({
          body: local.prep_login_credentials(res.body),
          error: function() {},
          fetchUser: false}).then(response => {
            //if (context.$auth.check()) {
              local.sync_data(context);
              Event.$emit('login-event', context.$auth.user().name)
              router.push( local.getRedirectTarget(context.$auth.user().roles) )
          })
      }
    })
  },


  log_in(context, credentials) {
    let local = this;
    context.$auth.login({
      body: credentials,
      error: function() {
        Event.$emit('login-error-event', 'Error de autenticación');
      },
      success: function(res) {
        //console.log(context.$auth.token());
        local.sync_data(context);
        Event.$emit('login-event', context.$auth.user().name)
        router.push( local.getRedirectTarget(context.$auth.user().roles) )
      },
      fetchUser: false,
      redirect: false
    })
  },


  log_out(context, redirect_to) {
    this.sync_data(context);
    console.log(context.$auth.user().name)
    Event.$emit('logout-event', context.$auth.user().name)
    context.$auth.logout({ redirect: redirect_to })
    //Event.$emit('logout-event', this.user.username)
    router.push( 'index' )
    this.clear_token(context);
  },

  expired_token_log_out(context, redirect_to) {
    this.sync_data(context);
    Event.$emit('expired-token-logout-event', context.$auth.user().name)
    context.$auth.logout({ redirect: redirect_to })
    this.clear_token(context);
  },

  sync_data(context) {
    context.$auth.user( this.decode_token( context.$auth.token() ));
  },

  decode_token(token) {
    let data = jwt_decode(token);
    return  { customer_id: data.customer_id, username: data.username, name: data.name, roles: data.roles };
  },


  clear_token(context) {
    localStorage.removeItem('jwt');
    context.$auth.user({});
  },

  prep_login_credentials(data) {
    let username = data.username;
    let password = data.password;
    let credentials = { auth: { username: username, password: password } }
    return credentials;
  },



  getRedirectTarget(roles) {
    if (roles.length == 0 ) {
      return '/welcome'
    }
    if ( roles.includes('admin') || roles.includes('technical') ||  roles.includes('sales') ||  roles.includes('accounting') || roles.includes('installer') ) {
      return '/admin'
    } 
  },



  check_authorization(authorized_roles) {
    /*
    this.sync_data(); 

    return this.user.roles.some( function( user_role ) {
      return authorized_roles.includes( user_role )
      });
      */
  }

}
