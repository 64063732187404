<template>
  <section class="hero is-dark is-fullheight login-form">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <img src="/images/logo-interdigita-small.png">
          <!--
          <h3 class="title has-text-grey">Login</h3>
          -->
          <!--
          <h3 class="subtitle has-text-grey">
            Introduzca su correo electrónico y contraseña para tener acceso al sistema.
          </h3>
          <p class="subtitle">
          -->
          <p>
            Introduzca su correo electrónico y contraseña para tener acceso al sistema.
          </p>
          <div class="box">
            <!--
            <figure class="avatar">
              <img src="https://placehold.it/128x128">
            </figure>
            -->
            <form>
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="auth.username" type="email" placeholder="Usuario" id="inputUsername" class="input is-large" autofocus="">
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="auth.password" type="password" placeholder="Password" id="inputPassword" class="input is-large">
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
              <!--
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox">
                  Remember me
                </label>
              </div>
              -->
              <button class="button is-block is-info is-large is-fullwidth"v-on:click="log_in">Acceder</button>
            </form>
          </div>
          <p class="has-text-grey">
            <a href="../">Registro</a> &nbsp;·&nbsp;
            <a href="../">Olvido contraseña</a> &nbsp;·&nbsp;
            <a href="../">Ayuda</a>
          </p>
        </div>
      </div>
    </div>
  </section>
<!--
  <div class='login-form columns'>
  <div class="column is-three-fifths is-offset-one-fifth">
      <section class="hero  is-bold is-dark">
          <img src="/images/logo-interdigita-small.png">
        <h1 class="title is-2"><i class="fa fa-user-circle-o fa-2x" ></i>Identifíquese</h1>
        <h3 class="subtitle"> Introduzca su correo electrónico y contraseña para tener acceso al sistema. </h3>
      </section>
      <div>
        <form >
          <fieldset>
            <div class="field">
              <div class="control has-icons-left">
                <input v-model="auth.email" type="email" placeholder="Email" id="inputEmail" class="input">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <div class="control has-icons-left">
                <input v-model="auth.password" type="password" placeholder="Password" id="inputPassword" class="input">
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <button class="button is-link is-fullwidth" v-on:click="log_in">Acceder</button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
-->
</template>

<script>

import authentication from '../packs/authentication'
import {router} from '../packs/interdigita'
export default {
  data: function () {
    return {
      auth: {
        email: "",
        password: ""
      }
    }
  },

  methods: {

    log_in: function(event) {
      event.preventDefault();
      authentication.log_in(this, { auth: {username: this.auth.username, password: this.auth.password} })
    },
    process_redirection: function() {
      if ( this.$auth.redirect() ) {
				Event.$emit('unauthorized-access-event', 'El recurso solicitado requiere identificación');
      }
    }

  },
  mounted() {
    this.process_redirection();
  }
}
</script>

