<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agendar Nueva 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         Usuario
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="user_form_name">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.name" placeholder="Jorge Campos" id="user_form_name" class="input" name="name">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('name')" v-text="errors.get('name')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="user_form_username">Nombre de Usuario</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.username" placeholder="mi_direccion@servidor.com" id="user_form_username" class="input">
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                    <span class="help is-danger" v-if="errors.has('username')" v-text="errors.get('username')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="user_form_roles_list">Roles</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <div class="block">
                  <b-checkbox v-model="form.roles_list" v-for="(key, value) in user_roles_list" v-bind:native-value="value">
                    {{ key }}
                  </b-checkbox>
                  </div>
                  <span class="help is-danger" v-if="errors.has('roles_list')" v-text="errors.get('roles_list')">
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="user_form_password">Contraseña</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.password" type="password" id="user_form_password" class="input">
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                    <span class="help is-danger" v-if="errors.has('password')" v-text="errors.get('password')">
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="user_form_password_confirmation">Confirmación</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.password_confirmation" type="password" id="user_form_password_confirmation" class="input">
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                    <span class="help is-danger" v-if="errors.has('password_confirmation')" v-text="errors.get('password_confirmation')">
                    </span>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>
<script>
  export default {
    props: ['form', 'list', 'user_roles_list'],
    data: function () {
      return {
        errors: this.form.errors
      }
    },
    methods: {
      fix_user_roles_list: function() {
//        this.user_form.form.roles_list = this.user_form.form.roles_list.split(
        console.log(this.form.roles_list)
      }
    },
    mounted() {
      this.fix_user_roles_list()
    }
  }
</script>
