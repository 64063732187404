<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar Nuevo 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         Dispositivo
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="field is-horizontal">
          <div class="field-label is-small">
            <label class="label" for="device_form_mac_address">Mac Address</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                  <input v-model="form.mac_address" placeholder="0B:23:AA:10:99:66" id="device_form_mac_address" class="input" name="mac_address">
                  <span class="icon is-small is-left">
                    <i class="fa fa-cog"></i>
                  </span>
                  <span class="help is-danger" v-if="errors.has('mac_address')" v-text="errors.get('mac_address')">
                  </span>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-small">
            <label class="label" for="device_form_serial_number">Serial Number</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                  <input v-model="form.serial_number" placeholder="" id="device_form_serial_number" class="input" name="serial_number">
                  <span class="icon is-small is-left">
                    <i class="fa fa-cog"></i>
                  </span>
                  <span class="help is-danger" v-if="errors.has('serial_number')" v-text="errors.get('serial_number')">
                  </span>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-small">
            <label class="label" for="device_form_device_model_id">Modelo</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                <div class="select">
                <select v-model="form.device_model_id"  id="device_form_device_model_id" class="input" name="device_model_id">
                  <option v-for="device_model in device_models_list" :value="device_model.id" :key="device_model.id">
                    {{ device_model.name }}
                  </option>
                </select>
                </div>
                  <span class="icon is-small is-left">
                    <i class="fa fa-briefcase"></i>
                  </span>
                <span class="help is-danger" v-if="errors.has('device_model_id')" v-text="errors.get('device_model_id')"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['form', 'list', 'device_models_list'],
    data: function () {
      return {
        errors: this.form.errors
      }
    }
  }
</script>
