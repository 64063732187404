<template>
  <div class="hero-body container">
    <!--
    <form @keydown="errors.clear($event.target.name)">
    -->
    <form  @submit.prevent="register">
      <section class="columns is-mobile is-centered">

        <div class="column is-four-fifths-desktop">
					<div class="level">
						<div class="level-item has-text-centered">
							<h3 class="title">
								Registro
							</h3>
						</div>
					</div>

          <div class="steps" id="stepsRegistration">

            <!-- step 1 -->
            <div class="step-item is-active is-success">
              <div class="step-marker">1</div>
              <div class="step-details">
                <p class="step-title">Tus Datos</p>
<!--
                <p>Indicanos telefóno o correo electronico y una contraseña </p>
-->
              </div>
            </div>
<!--
            <div class="step-item">
              <div class="step-marker">2</div>
              <div class="step-details">
                <p class="step-title">Direccion</p>
              </div>
            </div>


            customer-name
-->
            <div class="step-item">
              <div class="step-marker">2</div>
              <div class="step-details">
                <p class="step-title">Ubicación</p>
              </div>
            </div>

            <div class="steps-content">

              <div class="step-content">
								<div class="columns is-mobile is-centered">
									<div class="column is-two-thirds-desktop">

										<div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="customer_name">Nombre</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="control has-icons-left">
                            <input v-validate="'required|min:3'" name="registration.customer.name" v-model="registration.customer.name" placeholder="Nombre con apellidos" data-vv-as="nombre" type="text" class="input">
                            <span class="help is-danger">
                              <i v-show="errors.has('registration.customer.name')" class="fa fa-warning"></i>
                              {{ errors.first('registration.customer.name') }}
                            </span>
														<span class="icon is-small is-left">
															<i class="fa fa-male"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="customer_email">Correo Electrónico</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="control has-icons-left">
                            <input v-validate="'required|email'" name="registration.customer.email" v-model="registration.customer.email" placeholder="mi_direccion@servidor.com" type="text" data-vv-as="correo electronico" class="input">
                            <span class="help is-danger is-left">
                             <i v-show="errors.has('registration.customer.email')" class="fa fa-warning is-danger"></i>
                              {{ errors.first('registration.customer.email') }}
                            </span>

														<span class="icon is-small is-left">
															<i class="fa fa-envelope"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="telephone_number_form_number">Número Telefónico</label>
											</div>
											<div class="field-body">
												<div class="field" v-for="telephone_number in registration.customer.telephone_numbers_attributes">

													<div class="control has-icons-left">
                            <input v-validate="'required|numeric'" name="telephone_number.number" v-model="telephone_number.number" placeholder="998 123 4567" type="text" data-vv-as="numero telefonico" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('telephone_number.number')" class="fa fa-warning"></i>
                              {{ errors.first('telephone_number.number') }}
                            </span>
															<span class="icon is-small is-left">
																<i class="fa fa-phone"></i>
															</span>
													</div>

													<b-switch size="is-small is-info" v-model="telephone_number.is_mobile">
													<b-icon pack="fa" icon="mobile" size="small"></b-icon>
														Es Celular
													</b-switch>
													<b-switch size="is-small" v-model="telephone_number.is_whatsapp">
													<b-icon pack="fa" icon="whatsapp" size="small"></b-icon>
														Con WhatsApp
													</b-switch>
<!--
-->

												</div>


											</div>
										</div>

									</div>
<!--
									<div class="column">
										<div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="customer_password">Contraseña</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="control has-icons-left">
														<input v-model="form.primary_user.password" type="password" id="customer_password" class="input">
														<span class="help is-danger" v-if="errors.has('primary_user.password')" v-text="errors.get('primary_user.password')"></span>
														<span class="icon is-small is-left">
															<i class="fa fa-lock"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="customer_password_confirmation">Confirmación</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="control has-icons-left">
														<input v-model="form.primary_user.password_confirmation" type="password" id="customer_password_confirmation" class="input">
														<span class="help is-danger" v-if="errors.has('primary_user.password_confirmation')" v-text="errors.get('primary_user.password_confirmation')"></span>
														<span class="icon is-small is-left">
															<i class="fa fa-lock"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

									</div>
-->
								</div>
              </div>
              <div class="step-content has-text-centered">
								<div class="columns is-mobile is-centered">
									<div class="column is-two-thirds-desktop">

									<h3> Domicilio de instalacion del servicio </h3>
									<div v-if="request_address($mq)">
<!--
			              <strong> {{ this.declined_geolocation_request }} </strong>
-->
                  <div v-for="address in registration.customer.addresses_attributes">

                    <div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="address_form_street_address">Calle</label>
											</div>
											<div class="field-body">
                        <div class="field">

													<div class="control has-icons-left">
                            <input v-validate="'required|min:3'" name="address.street_address" v-model="address.street_address" placeholder="Calle, Manzana, Lote, Numero " type="text" data-vv-as="calle" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('address.street_address')" class="fa fa-warning"></i>
                              {{ errors.first('address.street_address') }}
                            </span>
													</div>
												</div>


											</div>
										</div>


                    <div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="address_form_secondary_address">Colonia</label>
											</div>
											<div class="field-body">
                        <div class="field">

													<div class="control has-icons-left">
                            <input v-validate="'required|min:3'" name="address.secondary_address" v-model="address.secondary_address" placeholder="SuperManzana, Región, Fraccionamiento" type="text" data-vv-as="colonia" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('address.secondary_address')" class="fa fa-warning"></i>
                              {{ errors.first('address.secondary_address') }}
                            </span>
													</div>
												</div>
											</div>
										</div>




                    <div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="address_form_secondary_address">Ciudad</label>
											</div>
											<div class="field-body">
                        <div class="field">
													<div class="control has-icons-left">
                            <input v-validate="'required|min:3'" name="address.city" v-model="address.city" placeholder="Cancun" type="text" data-vv-as="ciudad" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('address.city')" class="fa fa-warning"></i>
                              {{ errors.first('address.city') }}
                            </span>
													</div>
												</div>
											</div>
										</div>

                    <!-- agregar un menu ayudador de estados en view -->

                    <div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="address_form_state">Estado</label>
											</div>
											<div class="field-body">
                        <div class="field">
													<div class="control has-icons-left">
                            <input v-validate="'required'" name="address.state" v-model="address.state" placeholder="Quintana Roo" type="text" data-vv-as="estado" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('address.state')" class="fa fa-warning"></i>
                              {{ errors.first('address.state') }}
                            </span>
													</div>
												</div>
											</div>
										</div>
                    <!-- agregar un menu ayudador de paises en view -->
                    <div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="address_form_secondary_address">Codigo Postal</label>
											</div>
											<div class="field-body">
                        <div class="field">
													<div class="control has-icons-left">
                            <input v-validate="'required'" name="address.postal_code" v-model="address.postal_code" placeholder="77500" type="text" data-vv-as="codigo postal" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('address.postal_code')" class="fa fa-warning"></i>
                              {{ errors.first('address.postal_code') }}
                            </span>
													</div>
												</div>
											</div>
										</div>

                    <div class="field is-horizontal">
											<div class="field-label is-small">
												<label class="label" for="address_form_country">Pais</label>
											</div>
											<div class="field-body">
                        <div class="field">
													<div class="control has-icons-left">
                            <input v-validate="'required'" name="address.country" v-model="address.country" placeholder="Mexico" type="text" data-vv-as="pais" class="input">

                            <span class="help is-danger">
                              <i v-show="errors.has('address.country')" class="fa fa-warning"></i>
                              {{ errors.first('address.country') }}
                            </span>
													</div>
												</div>
											</div>
										</div>

									</div>
									</div>


									<div v-if="!request_address($mq)">
										<gmap-map style="height: 240px" class="map-container" :center="location" :zoom="16">
											<gmap-marker :position="location"> </gmap-marker>
										</gmap-map>
									</div>
                  <!--
										<vue-recaptcha :sitekey="recaptcha_key" size="invisible">
										</vue-recaptcha>
                            <input v-validate="'required|min:3'" name="registration.customer.name" v-model="registration.customer.name" placeholder="Nombre con apellidos" data-vv-as="nombre" type="text" class="input">
											<b-checkbox v-model="registration.accept_terms" v-validate="'required'" name="registration.accept_terms" data-vv-name="Aceptar terminos">Acepto los terminos de uso </b-checkbox>
                  -->
										<br/>
										<div class="field">

                      <label tabindex="0" class="b-checkbox checkbox"  aria-required="true" aria-invalid="false">
                        <input type="checkbox" name="registration.accept_terms" v-validate="'required'" true-value="true" value="false"> 
                        <span class="check"></span> <span class="control-label">Acepto los terminos de uso </span>
                      </label>

                      <i v-show="errors.has('registration.accept_terms')" class="fa fa-warning"></i>
                      <span class="help is-danger">{{ errors.first('registration.accept_terms') }}</span>

										</div>

										<button class="button is-success" :disabled="errors.any()" type="submit" >Solicitar servicio!</button>


									</div>
								</div>
              </div>


            </div>
						<div class="steps-actions">
							<div class="steps-action">
								<a href="#" data-nav="previous" class="button is-primary">
                  <span class="icon">
                    <i class="fa fa-arrow-circle-left"></i>
                  </span>
									<span> Anterior </span>
								</a>
							</div>
              <!-- <i v-show="errors.has('registration.accept_terms')" class="fa fa-warning"></i> -->
              <!-- en step 1 no mostrar el boton back, en step 2 no mostrar el forward -->
              <!-- O QUITAR POR COMPLETO LOS PASOS ? -->

							<div class="steps-action">
								<a href="#" data-nav="next" class="button is-primary">
									<span> Siguiente </span>
                  <span class="icon">
                    <i class="fa fa-arrow-circle-right"></i>
                  </span>
								</a>
							</div>
						</div>
          </div>

        </div>
      </section>

    </form>
  </div>
</template>
<script>
import bulmaSteps from 'bulma-steps/dist/js/bulma-steps.js'
import VueRecaptcha from 'vue-recaptcha';
import VueGeolocation from 'vue-browser-geolocation';

import {interdigita_api} from '../../packs/api.js'
import authentication from '../../packs/authentication'

// acomodar leyeda panel 2
// acomodar panel 1 se hace feo antes de cambiar a small screen

//falta el tema de facebook

export default {

  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  data: function () {
    return {


      location: {
					lat: null,
					lng: null
			},

      registration: {
        customer: {
          name: null,
          email: null,
          telephone_numbers_attributes: [
            {
              number: null,
              is_whatsapp: false,
              is_mobile: false
            }
          ],
          addresses_attributes: [
            {
              street_address: null,
              secondary_address: null,
              city: null,
              state: 'Quintana Roo',
              country: 'Mexico',
              postal_code: null,
              latitude: null,
              longitude: null
            }
          ]
        },
        accept_terms: false
      },
			registration_errors: {
				name: { msg: "El nombre proporcionado ya existe en el sistema", field: 'registration.customer.name' },
				email: { msg: "El correo electronico proporcionado ya existe en el sistema", field: 'registration.customer.email' },
				telephone_number: { msg: "El numero telefonico proporcionado ya existe en el sistema", field: 'telephone_number.number' },
				not_unique: "Si usted ya esta registrado puede pedir que le enviemos una nueva contraseña a su correo electronico"
			},

			declined_geolocation_request: false,
			recaptcha_key: interdigita_api.google_recaptcha_key
    }
  },
  methods: {

    register: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          authentication.register(this, this.registration );
        }
      });
		},

    parse_server_side_errors: function(server_errors) {
      let local = this;

      Object.keys(server_errors).forEach( function(key) {
        if (key == 'email' || key == 'name') {
          local._registration_errors_text(key);
        } else if  (key == 'primary_user.username' ) {
          /* this is a bit dirty
          el default es telphone_number
          y si fuera el email?
				telephone_number: { msg: "El numero telefonico proporcionado ya existe en el sistema", field: 'registration.customer.telephone_numbers_attributes[0].number' },
          */
          local._registration_errors_text('telephone_number');
        }

      })


			this.$dialog.alert({
				title: 'Error: Informacion Duplicada',
				message: this.registration_errors.not_unique,
				type: 'is-danger',
				hasIcon: true,
				icon: 'times-circle',
				iconPack: 'fa'
			})
		},

    _registration_errors_text: function(field_name) {
      //console.log(this.registration_errors[field_name].msg);
      this.errors.add({ field: this.registration_errors[field_name].field, msg: this.registration_errors[field_name].msg, rule: 'unique'});
    },

		request_address: function(mq) {
      if (mq == 'desktop') {
				return true;
			} else {
				if (this.declined_geolocation_request == true) {
					return true;
				} else {
					return false;
				}
			}
		},
		geolocate: function() {
		let self = this;
			VueGeolocation.getLocation({maximumAge: 0}).then(coordinates => {
				self.location = {
					lat: coordinates.lat,
					lng: coordinates.lng
				};

        self.registration.customer.addresses_attributes[0].latitude = self.location.lat;
        self.registration.customer.addresses_attributes[0].longitude = self.location.lng;
			}).catch(error=> {
				self.declined_geolocation_request = true;
			});
    }
  },
  created() {
//    this.$validator.localize('es');
  },
  mounted() {
		let self = this;

    self.location = {
      lat: parseFloat(interdigita_api.base_latitude),
      lng: parseFloat(interdigita_api.base_longitude)
    },


		new bulmaSteps( document.getElementById( 'stepsRegistration' ), {
			onShow: function(step) {
				if (step == 0) {
          document.querySelector('a[data-nav="previous"').style.visibility="hidden";
          document.querySelector('a[data-nav="next"').style.visibility="visible";
        } else if (step == 1) {
          self.geolocate();
          document.querySelector('a[data-nav="next"').style.visibility="hidden";
          document.querySelector('a[data-nav="previous"').style.visibility="visible";
				}
			}
		})


    /*
		let recaptcha = document.createElement('script');
		recaptcha.setAttribute('src', interdigita_api.google_recaptcha_uri);
		recaptcha.async = true
		recaptcha.defer = true
		document.head.appendChild(recaptcha);
    */
	//components: { VueRecaptcha }
  },

}
</script>
<style lang="css">
@import 'bulma-steps/dist/css/bulma-steps.min.css'
</style>
