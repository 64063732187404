<template>
  <div class="container is-fluid" sticky-container>
    <div class="box" v-sticky sticky-offset="offset" sticky-side="top" >
			<div class="level">
				<div class="level-item has-text-centered">
					<h3 class="title"> Clientes </h3>
				</div>
        <b-field>
          <b-autocomplete
            rounded
            v-model="name"
            placeholder="buscar"
            ref="autocomplete"
            icon="search" pack="fa">
          </b-autocomplete>
        </b-field>
	<!--
								:data="autocomplete_select"
    <div class="level is-mobile"  v-sticky sticky-offset="offset" sticky-side="top" >
	-->
			</div>
			<div class="level is-mobile">
				<div class="level-left">
					<button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
						<b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
						<span>Eliminar</span>
					</button>
				</div>
				<div class="level-right">
					<div class="level-item">
						<button class="button" @click.prevent="customer_payments_for(selected_element)" v-if="selected_element">
							<b-icon icon="credit-card" pack="fa" size="is-small"> </b-icon>
							<span>Pagos</span>
						</button>
						<button class="button" @click.prevent="edit_element(selected_element)" v-if="selected_element">
							<b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
							<span>Editar</span>
						</button>
					</div>
					<div class="level-item">
						<button class="button is-success" @click.prevent="new_element">
							<span>Agregar Cliente</span>
						</button>
					</div>
				</div>
			</div>
		</div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Informacion</th>
        </tr>
      </thead>
      <tbody v-for="customer in this.autocomplete_list">
        <tr @click.prevent="select_element(customer.id)" v-bind:class="{ 'is-selected': isElementSelected(customer.id) }">
          <td> {{ customer.name }} </td>
          <td>
            <span class="tag is-success" v-if="customer.documentation_complete">Documentos Completos</span>
            <br>
            <b-icon icon="envelope-o" pack="fa" size="is-small"> </b-icon>
            <span class="customer_email">
            {{ customer.email }}
            </span>
            <br>
            <b-icon icon="phone" pack="fa" size="is-small"> </b-icon>
            <span class="customer_telephone">
            {{ customer.telephone_number_primary }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import Form from '../../packs/form'

export default {
  data: function () {
    return {
      list: null,
      name: null,
      selected_element: null,
      form: new Form({
        id: null
      },
      this,
      'customers', ''
      )
    }
  },
	computed: {
		autocomplete_list: function() {
			if (this.name == null || this.name == "") {
				return this.list;
			} else {
				return this.list.filter(customer => customer.name.toLowerCase().indexOf(this.name.toLowerCase()) >= 0);
			}
		}
/*
		autocomplete_select: function() {
			if (this.list == null) {
				return [];
			} else {
				return this.autocomplete_list.map(x => x.name)
			}
		}
*/
	},

  methods: {
    component_setup: function() {
      this.$http.get("customers").then(response => {
        this.list = response.body;
      }, response => {})
    },

    select_element: function(element_id) {
     this.selected_element = ( this.selected_element == element_id) ? null : element_id
    },
    isElementSelected: function(element_id) {
     return (this.selected_element == element_id)
    },
    edit_element: function(element_id) {
      this.$router.push({ name: 'customer', params: { customer_id: element_id } })
    },
    new_element: function() {
      this.$router.push({ name: 'new_customer', params: { new_customer: true } })
    },
    customer_payments_for: function(element_id) {
      this.$router.push({ name: 'customer_payments', params: { customer_id: element_id } })
    },
    confirm_delete: function(element_id) {
      this.$dialog.confirm({
        message: "Desea eliminar este cliente?",
        onConfirm: () => {
          this.form.delete(element_id, this.list)
        }
      })
    }
  },
  mounted() {
    this.component_setup()
  }
}
</script>
