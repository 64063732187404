<template>
  <div class="container is-fluid" sticky-container>
    <div class="box" v-sticky sticky-offset="offset" sticky-side="top" >
      <div class="level">
        <div class="level-item has-text-centered">
          <h3 class="title"> Instalaciones para acceso a Internet </h3>
        </div>
      </div>
      <div class="level is-mobile">
        <div class="level-left">
          <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
            <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
            <span>Eliminar</span>
          </button>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button is-warning" @click.prevent="form.edit(selected_element, list)" v-if="failed_install_status()">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
              <span>Reprogramar</span>
            </button>
          </div>
          <div class="level-item">
            <button class="button is-info" @click.prevent="activate_subscription(selected_element);reload()" v-if="successful_install_status()">
              <span>Iniciar Subscripción</span>
            </button>
          </div>
          <div class="level-item">
            <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
              <span>Editar</span>
            </button>
          </div>
          <div class="level-item">
          <button class="button is-success" @click.prevent="form.new" v-if="!selected_element">
            <span>Agendar Instalacion</span>
          </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Cliente </th>
          <th> Equipos</th>
          <th> Instalación </th>
        </tr>
      </thead>
      <tbody v-for="install in list">
        <tr @click.prevent="select_element(install.id)" v-bind:class="{ 'is-selected': isElementSelected(install.id) }">
          <td> {{ install.customer_name }} 
          
          </td>
          <td>
            {{ install.radio_name }}
            <span class="install_list_mac">
              {{ install.radio_mac }}
            </span>
            <br/>
            {{ install.router_name }}
            <span class="install_list_mac">
              {{ install.router_mac }}
            </span>
          </td>
          <td>
            <span>
              {{ install.scheduled_datetime | format_day }}
            </span>
            <span>
              {{ install.scheduled_datetime | format_month }}
            </span>
            <span>
              {{ install.scheduled_datetime | format_time }}
            </span>
            <span class="tag is-success" v-if="install.status=='scheduled'">Programada</span>
            <span class="tag is-warning" v-if="install.status=='failed'">Fallida</span>
            <span class="tag is-primary" v-if="install.status=='successful'">Exitosa</span>
            <span class="tag is-primary" v-if="install.status=='successful' && install.subscription_status == 'not_subscribed'">Pendiente Activar</span>
            <span class="tag is-success" v-if="install.status=='successful' && install.subscription_status!='not_subscribed'">Activada</span>
            <br/>
            <b-icon icon="commenting-o" pack="fa" size="is-small"> </b-icon>
             {{ install.comments }} 

          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['list', 'form', 'subscription_form'],
  data: function () {
    return {
      selected_element: null
    }
  },
  methods: {
    activate_subscription: function(install_id) {
      this.selected_element = null
      this.subscription_form.render('new')
      this.subscription_form.install_id = install_id
      this.element = this.list.filter(item => item.id === install_id)[0] //find
      this.subscription_form.customer_name = this.element.customer_name
    },

    select_element: function(install_id) {
      this.selected_element = ( this.selected_element == install_id) ? null : install_id
    },
    isElementSelected: function(install_id) {
      return (this.selected_element == install_id)
    },
    failed_install_status: function() {
      if (this.selected_element == null) {
        return false;
      }
      let install = this.list.filter(item => item.id === this.selected_element)[0];
      return (install.status == 'failed') ? true : false;
    },
    successful_install_status: function() {
      if (this.selected_element == null) {
        return false;
      }
      let install = this.list.filter(item => item.id === this.selected_element)[0];
      return ( (install.status == 'successful') && (install.subscription_status == 'not_subscribed')) ? true : false;
    },
    confirm_delete: function(install_id) {
      this.$dialog.confirm({
        message: "Desea eliminar esta instalacion?",
        onConfirm: () => {
          this.selected_element = null
          this.form.delete(install_id, this.list)
        }
      })
    },
    reload: function() {
      this.$router.push('/admin/internet/installs');
    }
  },
  filters: {
    format_time: function (value) {
      if (!value) return ''
      return moment(value).format('hh:mm a');
    },
    format_day: function (value) {
      if (!value) return ''
      return moment(value).format('D');
    },
    format_month: function (value) {
      if (!value) return ''
      let m = moment(value).format('MMM');
      return (m.charAt(0).toUpperCase() + m.slice(1)).slice(0, -1);
    }
  }
}
</script>
