<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Administracion de personal </h3>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Nombre </th>
          <th> Usuario </th>
          <th> Roles </th>
        </tr>
      </thead>
      <tbody v-for="user in list">
        <tr @click.prevent="select_element(user.id)" v-bind:class="{ 'is-selected': isElementSelected(user.id) }">
          <td> {{ user.name }} </td>
          <td> {{ user.username }} </td>
          <td> {{ user.roles_list }} </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
          <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-success" @click.prevent="form.new">
            <span>Agregar Usuario</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authentication from '../../../packs/authentication'
import Errors from '../../../packs/errors'
import Form from '../../../packs/form'


export default {
  props: ['form', 'list', 'user_roles_list'],
  data: function () {
    return {
      selected_element: null
    }
  },

  methods: {
    select_element: function(user_id) {
     this.selected_element = ( this.selected_element == user_id) ? null : user_id
    },
    isElementSelected: function(user_id) {
     return (this.selected_element == user_id)
    },
    confirm_delete: function(user_id) {
     this.$dialog.confirm({
       message: "Desea eliminar ese usuario?",
       onConfirm: () => {
         this.form.delete(user_id, this.list)
       }
     })
    }
  },
  mounted() {
  }
}
</script>
