<template>
  <div class="container">
    <h3 class="title" style="text-align: center"> VENCIMIENTO DE PAGOS </h3>

    <div class="calendar-parent" v-show="!selected_day">
      <calendar-view
        :show-date="showDate"
        :events="events"
        :locale="'es-MX'" 
        @click-date="onClickDay"
        @click-event="onClickEvent"
        class="theme-default">
        <!--
        <calendar-view-header
          slot="header"
          slot-scope="t"
          :header-props="t.headerProps"
          @input="setShowDate" />
        -->
      </calendar-view>
    </div>

    <div v-if="selected_day != null">
      <table class="table is-striped is-fullwidth is-bordered list">
        <thead>
          <tr>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody v-for="customer in this.list[this.selected_day]">
          <tr @click.prevent="customer_payments_for(customer.id)">
            <td> {{ customer.name }} </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan=2></td>
          </tr>
        </tfoot>
      </table>
      <button class="button is-success is-outlined" @click="resetCalendar">
        << Volver al Calendario
      </button>
    </div>
  </div>
</template>

<script>

import authentication from '../../packs/authentication'
import Errors from '../../packs/errors'
import Form from '../../packs/form'
import { CalendarView, CalendarViewHeader, CalendarMathMixin } from "vue-simple-calendar"

require("vue-simple-calendar/static/css/default.css")
//require("vue-simple-calendar/static/css/holidays-us.css")

export default {
  mixins: [CalendarMathMixin],
  data: function () {
    return {
      list: null,
      selected_day: null,
 			events: [],
			showDate: new Date()
    }
  },
  methods: {
   cut_off_days_list_get() {
			this.$http.get("billing_cycle").then(response => {
        this.list = response.body;
        this.setup_calendar_events(this.events);
        }, response => {
      })
    },
		setShowDate(d) {
		  this.showDate = d;
	  },
    thisMonth(d, h, m) {
			const t = new Date()
			return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
		},
    setup_calendar_events(events) {
      for (const key in this.list) {

        let start_date = this.thisMonth(key);
        let day_events_count = 0;

        this.list[key].map(function (cust) {
          day_events_count = day_events_count +1;
        });

        events.push(
          {
            startDate: start_date,
            title: day_events_count
          }
        )
      }	
		},

    resetCalendar() {
      this.selected_day = null;
    },

    onClickDay(d) {
      this.selected_day = d.getDate()
		},

    onClickEvent(e) {
		  this.selected_day = e.startDate.getDate()
		},

    customer_payments_for: function(element_id) {
      this.$router.push({ name: 'customer_payments', params: { customer_id: element_id } })
    }

  },
	computed: {
		userLocale() {
			return this.getDefaultBrowserLocale
		}
  },
	components: {
			CalendarView,
			CalendarViewHeader,
	},
  mounted() {
   this.cut_off_days_list_get()
  }
}
</script>
