<style>
.vue-map-container,
.vue-map-container .vue-map {
    width: 100%;
    height: 100%;
}
</style>
<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar nueva
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
          dirección
        </h3>
      </div>
    </div>
    <customer-navigation v-bind:selected_tab="'addresses_tab'" v-bind:customer_id="customer_id">
    </customer-navigation>


    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">

        <div class="column">

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_street_address">Dirección</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.street_address" placeholder="Calle Farallon #5 MZ 7 Lote 1" id="address_form_street_address" class="input" name="street_address">
                    <span class="help is-danger" v-if="errors.has('street_address')" v-text="errors.get('street_address')"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_secondary_address">Colonia</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.secondary_address" placeholder="SM 39 Fracionamiento Privanza La Loma" id="address_form_secondary_address" class="input" name="secondary_address">
                    <span class="help is-danger" v-if="errors.has('secondary_address')" v-text="errors.get('secondary_address')"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_postal_code">Codigo Postal</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.postal_code" placeholder="77500" id="address_form_postal_code" class="input" name="postal_code">
                    <span class="help is-danger" v-if="errors.has('postal_code')" v-text="errors.get('postal_code')"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_latitude">Latitud</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.latitude" id="address_form_latitude" class="input" name="latitude">
                    <span class="help is-danger" v-if="errors.has('latitude')" v-text="errors.get('latitude')"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_longitude">Longitud</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.longitude" id="address_form_longitude" class="input" name="longitude">
                    <span class="help is-danger" v-if="errors.has('longitude')" v-text="errors.get('longitude')"></span>
                  </div>
                </div>
              </div>
            </div>



        </div>
        <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_city">Ciudad</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.city" placeholder="Cancun" id="address_form_city" class="input" name="city">
                    <span class="help is-danger" v-if="errors.has('city')" v-text="errors.get('city')"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_state">Estado</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.state" placeholder="Quintana Roo" id="address_form_state" class="input" name="state">
                    <span class="help is-danger" v-if="errors.has('state')" v-text="errors.get('state')"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_country">Pais</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input v-model="form.country" placeholder="Mexico" id="address_form_country" class="input" name="country">
                    <span class="help is-danger" v-if="errors.has('country')" v-text="errors.get('country')"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label" for="address_form_location">Ubicacion</label>
              </div>

              <div class="field-body" style="margin-left: 2em;">
              <!--
            <gmap-map style="width: 400px; height: 240px" class="map-container" 
            :center="{lat:21.15, lng:-86.84}" :zoom="12">
    <gmap-info-window :position="{lat:1.38, lng:103.8}">
      Hello world!
    </gmap-info-window>
              -->
                <gmap-map style="width: 400px; height: 240px" class="map-container" :center="location" :zoom="16">
                  <gmap-marker :position="location"> </gmap-marker>
                </gmap-map>
              </div>
            </div>

        </div>
      </section>

      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import customer_navigation from './../navigation'
  export default {
    props: ['form', 'list', 'customer_id'],
    data: function () {
      return {
        errors: this.form.errors,
        location: {
          lat: parseFloat(this.form.latitude),
          lng: parseFloat(this.form.longitude)
        }
      }
    },
    components: {
      'customer-navigation': customer_navigation
    },
    methods: {
      setup_location: function(form) {
      /*
        console.log(this.customer_id)
        this.location = {
          lat: parseFloat(this.form.latitude),
          lng: parseFloat(this.form.longitude)
        }
        */
      }
    },
    mounted() {
      this.setup_location(this.form)
    }
  }
</script>
