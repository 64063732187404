
<template>
  <div class="navbar-item">
      <button class="button is-warning interdigita-button is-small" v-on:click="log_out" v-if="user_logged_in">
        <b-icon pack="fa" icon="sign-out"></b-icon>
        <span>Salir</span>
      </button>
    <!--
  <div class="navbar-item">
    <div v-if="user_logged_in">
      <button class="button is-warning interdigita-button is-small" v-on:click="log_out">

    </div>
    <div v-else>
      <button class="button is-success interdigita-button is-small" v-on:click="login_form">
        <b-icon pack="fa" icon="male"></b-icon>
        <span>Mi Cuenta</span>
      </button>
    </div>
    -->
  </div>
</template>

<script>

import authentication from '../packs/authentication'

export default {
  data: function () {
    return {
      user_logged_in: this.$auth.check()
    }
  },



  methods: {

    log_out: function(event) {
      this.user_logged_in = false;
      authentication.log_out(this, '/')

    },

    login_form: function(event) {
      location.href = "/login"
    },


    process_logout_event: function(event) {
      this.user_logged_in =  false
    },
    process_login_event: function(event) {
      this.user_logged_in =  true
    },

  },
  created: function () {
    Event.$on('login-event', this.process_login_event);
    Event.$on('logout-event', this.process_logout_event);
    Event.$on('expired-token-logout-event', this.process_logout_event);
  }
}

</script>
