<template>
  <section class="section is-fullwidth" id="main-slider">
    <div class="hero">
    <button class="button is-success interdigita-button" v-on:click="log_in" v-show="isSelectedTab('home-tab')">
        <b-icon pack="fa" icon="sign-in"></b-icon>
        <span> <strong> ACCEDER A SU CUENTA </strong> </span>
    </button>
    </div>

      <div class="hero" id="home-tab" v-if="isSelectedTab('home-tab')">
        <div class="hero-body container">
          <h1>Internet para Todos!</h1>
            <p class="price">
              <sup>$</sup>
              <span>400</span>
              <sub>/mes.</sub>
            </p>
            <p class="hint">El internet más rápido con el servicio y atención que te mereces.</p>
            <h3 class="">Así de simple.</h3>
        </div>
      </div>

      <div class="hero" id="cobertura-tab" v-if="isSelectedTab('cobertura-tab')">
        <div class="hero-body container ">
          <div class="columns">
            <div class="column">
            </div>
            <div class="column">
              <h1>Nuevas Zonas<br>
                de Cobertura.
              </h1>
              <p> Alamos, Aqua, Arboledas, Astoria, Cumbres, Huayacan, Isla Blanca,  Paseos Chac Mool, Rancho Viejo,
              Rinconadas del Mar, Santa Fe 1-2-3, Gran Santa Fe 3, Residencial Alejandria, Villamarino, Villas del Arte, Villas del Mar 1-2-3-Plus </p>

              <button class="button is-success" v-on:click="register">Contratar Ahora</button>
            </div>
          </div>
        </div>
      </div>

      <div class="hero" id="support-tab" v-if="isSelectedTab('support-tab')">
      </div>

      <div class="hero" id="facil-tab" v-if="isSelectedTab('facil-tab')">
        <div class="hero-body container">
          <h1>Sin contrato ni plazo forzoso, ni gastos de instalación</h1>
          <p> Interdigita solicita un deposito de 1200 pesos para garantizar la devolucion de los equipos que dejamos en tu domicilio. 
          <p class="hint">Nuestro servicio se contrata facil y se cancela facil, si por alguna razon dejas de requerir nuestro servicio puedes cancelarlo
          sin penalizacion, y te devolveremos tu deposito en efectivo y en tu mano en el momento que nos permitas acudir a desinstalar los equipos que te brindaban el servicio</p>
          <p> Consultar <button class="btn btn-info btn-lg" type="submit">terminos y condiciones</button> o 
            <button class="button is-success" v-on:click="register">Contratar Ahora</button> 
          </p>
        </div>
      </div>

    <div class="hero" id="register-tab" v-if="isSelectedTab('register-tab')">
      <!--
        <registration-component v-bind:form="registration_form">
      -->
      <registration-component>
        </registration-component>
    </div>

    <div class="tabs is-fullwidth" id="main-slider-tabs">
      <ul>
        <li class="slider-tab" @click.prevent="toggle_tab('home-tab')" v-bind:class="[ isSelectedTab('home-tab') ? 'is-active' : ''  ]">
          <a href="#home">
            <div class="info-icon"><i class="fa fa-wifi fa-3x"></i></div>
            <div class="info-title">Internet Inalámbrico</div>
            <div class="info-circle"><div class="circle-icon"></div></div>
          </a>
        </li>
        <li class="slider-tab" @click.prevent="toggle_tab('cobertura-tab')" v-bind:class="[ isSelectedTab('cobertura-tab') ? 'is-active' : ''  ]">
          <a href="#cobertura">
            <div class="info-icon"><i class="fa fa-map-marker fa-3x"></i></div>
            <div class="info-title">Gran cobertura en Cancun</div>
            <div class="info-circle"><div class="circle-icon"></div></div>
          </a>
        </li>
        <li class="slider-tab" @click.prevent="toggle_tab('support-tab')" v-bind:class="[ isSelectedTab('support-tab') ? 'is-active' : ''  ]">
          <a href="#support">
            <div class="info-icon"><i class="fa fa-heartbeat fa-3x"></i></div>
            <div class="info-title">Monitoreamos que funcione al 100%</div>
            <div class="info-circle"><div class="circle-icon"></div></div>
          </a>
        </li>
        <li class="slider-tab" @click.prevent="toggle_tab('facil-tab')" v-bind:class="[ isSelectedTab('facil-tab') ? 'is-active' : ''  ]">
          <a href="#facil">
            <div class="info-icon"><i class="fa fa-handshake-o fa-3x"></i></div>
            <div class="info-title">Sin gastos de instalación</div>
            <div class="info-circle"><div class="circle-icon"></div></div>
          </a>
        </li>
        <li class="slider-tab" @click.prevent="toggle_tab('register-tab')" v-bind:class="[ isSelectedTab('register-tab') ? 'is-active' : ''  ]">
          <a href="#instalacion">
            <div class="info-icon"><i class="fa fa-calendar fa-3x"></i></div>
            <div class="info-title">Agenda tu Instalacion!</div>
            <div class="info-circle"><div class="circle-icon"></div></div>
          </a>
        </li>
      </ul>
    </div>

<!--
      <div class="row">
        <div id="carousel-example-generic" class="carousel slide box" data-ride="carousel">
          <div class="carousel-inner">
            <div class="item" id="slide-2">
              <div class="container">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 bg-orange-rgba pull-right">
                    <div class="col-sm-6 img-border">
                      <h1>Sin contrato ni plazo forzoso
                              </h1>
                      <p>Nuestro servicio se contrata facil y se cancela facil, si por alguna razon dejas de requerir nuestro servicio puedes cancelarlo
                              sin penalizacion, y te devolveremos tu deposito en el momento que nos permitas desinstalar los equipos que Interdigita haya instalado en tu domicilio</p>
                      <button class="btn btn-success btn-lg" type="submit">Consultar terminos y condiciones</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      1. internet inalambrico ( main )
      2  sin gastos de instalacion (devolvemos deposito
      3. cobertura
      4. monitoreo ( falta imagenes de soporte tecnico )
      5. agenda instalacion ( registro )
-->
  </section>
</template>

<script>
import Form from '../packs/form'
import registration_component from './registration/form'
export default {
  data: function () {
    return {
      active_tab: 'home-tab'
          /*
      ,
      registration_form: new Form({
        customer: {
          name: null,
          email: null,
          */

          /*
          proof_of_identity: null,
          proof_of_address: null,
          proof_of_identity_url: null,
          proof_of_address_url: null,
          */
            /*
          primary_user: {
            password: null,
            password_confirmation: null
          },
            */
            /*
          primary_telephone_number: {
            number: null,
            is_whatsapp: false,
            is_mobile: false
          },
          primary_address: {
            street_address: null,
            secondary_address: null,
            city: null,
            state: 'Quintana Roo',
            country: 'Mexico',
            postal_code: null,
            latitude: null,
            longitude: null
          }
        },
        accept_terms: false
      },
      this,
      'registration', ''
      )
            */
    }
  },
  methods: {

    toggle_tab:  function(selectedTab) {
      this.active_tab = selectedTab
    },

    isSelectedTab: function(element) {
      return this.active_tab == element
    },

    log_in: function(event) {
      location.href = "/login"
    },

    register: function(event) {
      location.href = "/registro"
    }
  },
  components: {
    'registration-component': registration_component
  },
}
</script>
