<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar nuevo 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         plan de servicio 
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label" for="service_plan_form_name">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.name" placeholder="Plan de servicio ejemplo" id="service_plan_name" class="input" name="name">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('name')" v-text="errors.get('name')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label ">
              <label class="label" for="service_plan_form_price">Precio</label>
            </div>
            <div class="field-body">
              <div class="field has-addons"> 
                <div class="control has-icons-left">
                  <input v-model="form.price" placeholder="100" id="service_plan_price" class="input " name="price">
                  <span class="icon is-small is-left">
                    <i class="fa fa-money"></i>
                  </span>
                  <span class="help is-danger" v-if="errors.has('price')" v-text="errors.get('price')">
                  </span>
                </div>
                <div class="control">
                  <div class="select">
                    <select v-model="form.price_currency"  id="service_plan_price_currency" class="input" name="price_currency">
                      <option v-for="currency in currency_list" :value="currency" :key="currency">
                        {{ currency }}
                      </option>
                    </select>
                  </div>
                  <span class="help is-danger" v-if="errors.has('price_currency')" v-text="errors.get('price_currency')"></span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label" for="service_plan_form_downlink_speed">Velocidad </label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
								<div class="control">
									<a class="button is-static"> Descarga </a>
								</div> 
                <div class="control has-icons-left">
                  <input v-model="form.downlink_speed" placeholder="10000" id="service_plan_downlink_speed" class="input" name="downlink_speed">
                  <span class="icon is-small is-left">
                    <i class="fa fa-arrow-circle-o-down"></i>
                  </span>
                  <span class="help is-danger" v-if="errors.has('downlink_speed')" v-text="errors.get('downlink_speed')">
                  </span>
                </div>
								<div class="control">
									<a class="button is-static"> Subida </a>
								</div> 
                <div class="control has-icons-left">
									<input v-model="form.uplink_speed" placeholder="3000" id="service_plan_uplink_speed" class="input" name="uplink_speed">
									<span class="icon is-small is-left">
										<i class="fa fa-arrow-circle-o-up"></i>
									</span>
									<span class="help is-danger" v-if="errors.has('uplink_speed')" v-text="errors.get('uplink_speed')">
									</span>
                </div>

              </div>
            </div>
          </div>
<!--
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label" for="service_plan_form_uplink_speed">Velocidad de subida (Kbps) </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.uplink_speed" placeholder="3000" id="service_plan_uplink_speed" class="input" name="uplink_speed">
                    <span class="icon is-small is-left">
                      <i class="fa fa-arrow-circle-o-up"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('uplink_speed')" v-text="errors.get('uplink_speed')">
                    </span>
                </div>
              </div>
            </div>
          </div>
-->
        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="fix_price_after_edit();form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['form', 'list'],
    data: function () {
      return {
        currency_list: ['USD', 'MXN'],
        errors: this.form.errors
      }
    },
    methods: {
      fix_price_before_edit: function() {
        if (this.form.mode == 'edit') {
          this.form.price = this.form.price_amount;
        }
      },
      fix_price_after_edit: function() {
        if (this.form.mode == 'edit') {
         this.form.price_formatted = this.form.price;
         this.form.price_amount = this.form.price;
        }
      }
    },
    mounted() {
      this.fix_price_before_edit()
    }
  }
</script>
