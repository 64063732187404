<template>
  <div>
    <transition name="fade">
      <list-component v-bind:form="service_plan_form" v-bind:list="list" v-show="!service_plan_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="service_plan_form" v-bind:list="list" v-if="service_plan_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import authentication from '../../../packs/authentication'
import Errors from '../../../packs/errors'
import Form from '../../../packs/form'

import service_plan_form from './service_plans/form'
import service_plan_list from './service_plans/list'

export default {
  data: function () {
    return {
      list: null,
      selected_element: null,
      service_plan_form: new Form({
        id: null,
        name: null,
        price_formatted: null,
        price: null,
        price_amount: null,
        price_currency: null,
        uplink_speed: null,
        downlink_speed: null,
        },
        this,
        'internet/service_plans', '/'
      )
    }
  },

  methods: {
    service_plans_list_get() {
      this.$http.get("internet/service_plans/").then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },
  components: {
    'form-component': service_plan_form,
    'list-component': service_plan_list
  },
  mounted() {
    this.service_plans_list_get()
  }
}

</script>
