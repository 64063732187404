<template>
</template>
<script>


export default {
  created: function () {
    Event.$on('registration-error-event', ($text) => {
      this.$toast.open({
        message: $text,
        duration: 5000,
        type: 'is-warning',
        position: 'is-bottom-right'
      })
    }),

    Event.$on('login-error-event', ($text) => {
      let message = `
			<span class="icon is-medium">
				<i class="fa fa-lock"></i>
			</span>
       ${$text}
      `;
      this.$toast.open({
        message: message,
        duration: 5000,
        type: 'is-warning',
        position: 'is-bottom-right'
      })
    }),

    Event.$on('login-event', ($text) => {
      let message = `
			<span class="icon is-medium">
				<i class="fa fa-star"></i>
			</span>
       <b>${$text}</b> ha iniciado sesión
      `;
      this.$toast.open({
        message: message,
        duration: 5000,
        type: 'is-success',
        position: 'is-bottom-right'
      })
    }),

    Event.$on('logout-event', ($text) => {
      let message = `
			<span class="icon is-medium">
				<i class="fa fa-hand-peace-o"></i>
			</span>
       <b>${$text}</b> ha cerrado su sesión
      `;
      this.$toast.open({
        message: message,
        duration: 5000,
        type: 'is-success',
        position: 'is-bottom-right'
      })
    }),

    Event.$on('expired-token-logout-event', ($text) => {
      let message = `
			<span class="icon is-medium">
				<i class="fa fa-clock-o"></i>
			</span>
       <b>${$text}</b> su sesion ha finalizado por inactividad
      `;
      this.$toast.open({
        message: message,
        duration: 5000,
        type: 'is-warning',
        position: 'is-bottom-right'
      })
    }),

    Event.$on('unauthorized-access-event', ($text) => {
      let message = `
			<span class="icon is-medium">
				<i class="fa fa-lock"></i>
			</span>
       ${$text}
      `;
      this.$toast.open({
        message: message,
        duration: 5000,
        type: 'is-danger',
        position: 'is-bottom-right'
      })
    })

  }
}

</script>
