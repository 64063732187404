<template>
  <div>
    <transition name="fade">
      <subscription-form-component v-bind:form="subscription_form" v-bind:list="empty_list"
        v-bind:service_plans_list="service_plans_list" 
        v-bind:status_list="subscription_status_list" v-if="subscription_form.is_active">
      </subscription-form-component>
    </transition>
    <transition name="fade">
      <list-component v-bind:form="install_form" v-bind:list="list" 
        v-bind:subscription_form="subscription_form" v-show="!install_form.is_active && !subscription_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="install_form" v-bind:list="list" v-bind:status_list="status_list"
        v-bind:customers_data="customers_list" 
        v-if="install_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import authentication from '../../../packs/authentication'
import Errors from '../../../packs/errors'
import Form from '../../../packs/form'

import install_form from './installs/form'
import install_list from './installs/list'
import subscription_form from './subscriptions/form'

export default {
  data: function () {
    return {
      empty_list: [],
      list: null,
      selected_element: null,
      status_list: null,
      subscription_status_list: null,
      customers_list: null,
      service_plans_list: null,
      subscription_form: new Form({
        id: null,
        customer_name: null,
        install_id: null,
        service_plan_id: null,
        service_plan_name: null,
        start_date: new Date().toISOString(),
        cut_off_day: null,
        alternate_cut_off_day: null,
        status: null
        },
        this,
        'internet/subscriptions', '/'
      ),
      install_form: new Form({
        id: null,
        customer_id: null,
        customer_name: null,
        radio_id: null,
        radio_name: null,
        router_id: null,
        router_name: null,
        ssid_name: null,
        ssid_password: null,
        comments: null,
        scheduled_datetime: new Date().toISOString(),
        status: null,
        subscription_status: null
        },
        this,
        'internet/installs', '/'
      )
    }
  },

  methods: {
    installs_list_get() {
      this.$http.get("internet/installs/").then(response => {
        this.list = response.body
        }, response => {
      })
    },
    install_status_list_get() {
      this.$http.get("internet/installs/statuses").then(response => {
        this.status_list = response.body
        }, response => {
      })
    },
    service_plans_list_get() {
      this.$http.get("internet/service_plans/").then(response => {
        this.service_plans_list = response.body
        }, response => {
      })
    },
    subscription_status_list_get() {
      this.$http.get("internet/subscriptions/statuses").then(response => {
        this.subscription_status_list = response.body
        }, response => {
      })
    },
    customers_menu_list_get() {
      this.$http.get("customers/menu").then(response => {
        this.customers_list = response.body
        }, response => {
      })
    }
  },
  components: {
    'subscription-form-component': subscription_form,
    'form-component': install_form,
    'list-component': install_list
  },
  mounted() {
    this.installs_list_get()
    this.install_status_list_get()
    this.customers_menu_list_get()
    this.service_plans_list_get()
    this.subscription_status_list_get()

  }
}
</script>
