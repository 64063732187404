<template>
  <div class="container is-fluid">
    <!-- component -->
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Planes de acceso a Internet </h3>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Nombre </th>
          <th> Precio </th>
          <th> Velocidad  </th>
        </tr>
      </thead>
      <tbody v-for="service_plan in list">
        <tr @click.prevent="select_element(service_plan.id)" v-bind:class="{ 'is-selected': isElementSelected(service_plan.id) }">
          <td> {{ service_plan.name }} </td>
          <td>
            $
            <span class="service_plan_amount">
            {{ service_plan.price_amount | format_amount}}
            </span>
            <span class="service_plan_cents">
            {{ service_plan.price_amount | format_cents }}
            </span>
            <span class="service_plan_currency">
             {{ service_plan.price_currency }}
            </span>
          </td>
          <td>
            <span class="service_plan_downlink_speed">
             <span class="icon is-left is-small is-success">
             <i class="fa fa-arrow-circle-down"></i>
            </span>
              <span class="service_plan_megabits">
                {{ service_plan.downlink_speed | internet_speed_megabits }}
              </span>
            </span>
            /
            <span class="service_plan_uplink_speed">
              <span class="service_plan_megabits">
                {{ service_plan.uplink_speed | internet_speed_megabits }}
              </span>
            </span>

            <span class="service_plan_speed_unit">Mbps</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
        <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
        <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
          <span>Editar</span>
        </button>
        </div>
        <div class="level-item">
        <button class="button is-success" @click.prevent="form.new">
          <span>Nuevo</span>
        </button>
        </div>
      </div>
    </div>
  <!-- component -->
  </div>
</template>
<script>
export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null
    }
  },
  methods: {
    select_element: function(service_plan_id) {
      this.selected_element = ( this.selected_element == service_plan_id) ? null : service_plan_id
    },
    isElementSelected: function(service_plan_id) {
      return (this.selected_element == service_plan_id)
    },
    confirm_delete: function(service_plan_id) {
      this.$dialog.confirm({
        message: "Desea eliminar ese plan de servicio?",
        onConfirm: () => {
          this.form.delete(service_plan_id, this.list)
        }
      })
    }
  },
  filters: {
    internet_speed_megabits: function (value) {
      if (!value) return ''
      let speed = value + '';
      let megabits = speed.substr(0, speed.length - 3);
      return megabits;
    },
    format_amount: function (value) {
      if (!value) return ''
      return value.substr(0, value.length - 3);
    },
    format_cents: function (value) {
      if (!value) return ''
      return value.substr(value.length - 2);
    }
  }
}
</script>

