<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Crear  
          </template>
          <template v-if="form.mode == 'edit'">
          Editar 
          </template>
        acceso al sistema 
        </h3>
      </div>
    </div>
    <customer-navigation v-bind:selected_tab="'access_tab'" v-bind:customer_id="customer_id">
    </customer-navigation>
    <!--
      ESTE TAB SOLO ES access/form Y CAMBIA ENTRE MODO new Y edit

      //necesito un control en el API para actualizar usuarios
      
      INDICAR EN EL LISTADO DE USUARIOS SI UN USUARIO (con una llavecita verde o gris)
      esta activado para LOGIN

      usar vee validate

      EL LOGIN DEBE DE TENER LA OPCION
      regenerar contraseña...

      accesos autorizados  email o uno de los telefonos.... 
      [email duplicado o telefono duplicado no puede quedar grabado como username en el autoregistro]

      USUARIO PUEDE CAMBIAR SU USERNAME Y CONTRASENA PERO DEBE DE PASAR LA VALIDACION


      SI EL TELEFONO EXISTE y EL EMAIL EXISTE generar username


      ESTE COMPONENTE NO DEBERIA ESTAR VISIBLE PARA CLIENTES SUPER CLIENTE
    -->
    <form>
      <section class="columns">
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="username">Nombre de usuario</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.username" placeholder="Use su correo electronico, numero de telefono, etc" id="useername" class="input">
                  <!--
                  <span class="help is-danger" v-if="errors.has('username')" v-text="errors.get('username')"></span>
                  -->
                  <span class="icon is-small is-left">
                    <i class="fa fa-male"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="password">Contraseña</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.password" type="password" id="password" class="input">
                  <!--
                  <span class="help is-danger" v-if="errors.has('password')" v-text="errors.get('password')"></span>
                  -->
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="password_confirmation">Confirmación</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input v-model="form.password_confirmation" type="password" id="password_confirmation" class="input">
                  <!--
                  <span class="help is-danger" v-if="errors.has('primary_user.password_confirmation')" v-text="errors.get('primary_user.password_confirmation')"></span>
                  -->
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import customer_navigation from './navigation'
  import Form from '../../../packs/form'
  export default {
    props: ['customer_id', 'form_mode'],
    $_veeValidate: {
      validator: 'new'
    },
    data: function () {
      return {
        form: new Form({
          id: null,
          username: null,
          password: null,
          password_confirmation: null
        },
        this,
        'staff/users', '/'
        ),
      }
    },
    methods: {
      setup_form() {
        if (this.customer_id != null) {
          let primary_user = null;
          //this.$http.get("staff/users").then(response => {
          // console.log(response.body)
          //  }, response => {
          //})
          this.$http.get(`customers/${this.customer_id}`, { }).then(
            response =>  { 
              if (response.body) {
                primary_user = response.body.primary_user;
                if (primary_user != null) {
                  this.form.id = primary_user.id;
                  this.form.username = primary_user.username;
                }
              }
            }
          )
        }
        this.form.mode = this.form_mode
      },
    },
    components: {
      'customer-navigation': customer_navigation
    },
    beforeMount() {
      this.setup_form()
    },
    created() {
      this.$validator.localize('es');
    }
  }
</script>
