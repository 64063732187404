<template>
  <div class="container is-fluid" sticky-container>
    <div class="box" v-sticky sticky-offset="offset" sticky-side="top" >
      <div class="level">
        <div class="level-item has-text-centered">
          <h3 class="title"> Subscripciones </h3>
        </div>
      </div>
      <div class="level is-mobile">
        <div class="level-left">
          <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
            <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
            <span>Eliminar</span>
          </button>
        </div>
        <div class="level-right">
          <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
          <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
          </div>
          <div class="level-item">
            <!--
          <button class="button is-success" @click.prevent="form.new">
            <span>Nuevo</span>
          </button>
            -->
          <!--
          <button class="button is-success is-pulled-right" @click.prevent="subscription_form.form.new">
            <span>Iniciar Subscripción</span>
          </button>
          -->
          </div>
        </div>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Plan</th>
          <th>Activación</th>
          <th>Corte</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody v-for="subscription in list">
        <tr @click.prevent="select_element(subscription.id)" v-bind:class="{ 'is-selected': isElementSelected(subscription.id) }">
          <td> {{ subscription.customer_name }} </td>
          <td> {{ subscription.service_plan_name }} </td>
          <td class="subscription_start_date">
            <span>
              {{ subscription.start_date | format_day }}
            </span>
            <span>
              {{ subscription.start_date | format_month }}
            </span>
            <span>
              {{ subscription.start_date | format_year }}
            </span>
          </td>
          <td>
            <span class="list_important_number">
             {{ subscription.cut_off_day }}
            </span>
          </td>
          <td> {{ subscription.status }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null
    }
  },
  methods: {
    select_element: function(subscription_id) {
      this.selected_element = ( this.selected_element == subscription_id) ? null : subscription_id
    },
    isElementSelected: function(subscription_id) {
      return (this.selected_element == subscription_id)
    },
    confirm_delete: function(subscription_id) {
      this.$dialog.confirm({
        message: "Desea eliminar esta subscripcion?",
        onConfirm: () => {
          this.selected_element = null
          this.form.delete(subscription_id, this.list)
        }
      })
    }
  },
  filters: {
    format_day: function (value) {
      if (!value) return ''
      return moment(value).format('D');
    },
    format_month: function (value) {
      if (!value) return ''
      let m = moment(value).format('MMM');
      return (m.charAt(0).toUpperCase() + m.slice(1)).slice(0, -1);
    },
    format_year: function (value) {
      if (!value) return ''
      return moment(value).format('YY');
    }
  }
}
</script>
