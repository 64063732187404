<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Direcciones de Red </h3>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Direccion IP </th>
          <th> Prefijo </th>
          <th> Tipo </th>
          <th> Descripcion </th>
          <th> Subred </th>
          <th> Grupo </th>
        </tr>
      </thead>
      <tbody v-for="network_address in list">
        <tr @click.prevent="select_element(network_address.id)" v-bind:class="{ 'is-selected': isElementSelected(network_address.id) }">
          <td> {{ network_address.address }} </td>
          <td> {{ network_address.prefix }} </td>
          <td> {{ network_address.address_type }} </td>
          <td> {{ network_address.description }} </td>
          <td> {{ network_address.parent_subnet_render }} </td>
          <td> {{ network_address.network_address_group_name }} </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-success" @click.prevent="form.new">
            <span>Agregar Dirección de Red</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null
    }
  },

  methods: {
    select_element: function(element_id) {
     this.selected_element = ( this.selected_element == element_id) ? null : element_id
    },
    isElementSelected: function(element_id) {
     return (this.selected_element == element_id)
    },

    confirm_delete: function(element_id) {
      this.$dialog.confirm({
        message: "Desea eliminar esta direccion de red?",
        onConfirm: () => {
          this.form.delete_reload(element_id, this.list)
        }
      })
    }
  }
}
</script>
