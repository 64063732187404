<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="network_address_form" v-bind:list="list" v-show="!network_address_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="network_address_form" v-bind:list="list"
          v-bind:network_address_groups_list="network_address_groups_list" v-if="network_address_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../packs/form'

import network_address_form from './network_addresses/form'
import network_address_list from './network_addresses/list'

export default {
  data: function () {
    return {
      list: null,
      network_address_groups_list: null,
      network_address_form: new Form({
        id: null,
        address: null,
        description: null,
        prefix: null,
        network_address_group_id: null,
        network_address_group_name: null,
        address_type: null,
        parent_subnet_id: null,
        dhcp_pool: null,
        parent_subnet_render: null
        },
        this,
        'inventory/network_addresses', ''
      )
    }
  },
  methods: {
    /* rework get methods, inject the variable where we want to 
       receive the data, and send the uri as argument
       */
    network_address_list_get: function() {
      this.$http.get("inventory/network_addresses").then(response => {
        this.list = response.body
        }, response => {
      })
    },
    network_address_groups_list_get: function() {
      this.$http.get("inventory/network_address_groups").then(response => {
        this.network_address_groups_list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': network_address_form,
    'list-component': network_address_list
  },
  mounted() {
    this.network_address_list_get()
    this.network_address_groups_list_get()
  }
}
</script>

