<template>
  <div class="container is-fluid">
    <div class="box" v-sticky sticky-offset="offset" sticky-side="top" >
      <div class="level">
        <div class="level-item has-text-centered">
          <h3 class="title"> Dispositivos </h3>
        </div>
        <b-field>
          <b-autocomplete
            rounded
            v-model="name"
            placeholder="buscar"
            ref="autocomplete"
            icon="search" pack="fa">
          </b-autocomplete>
        </b-field>
      </div>
      <div class="level is-mobile">
        <div class="level-left">
          <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
            <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
            <span>Eliminar</span>
          </button>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
              <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
              <span>Editar</span>
            </button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.new">
              <span>Agregar Dispositivo</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Modelo </th>
          <th> Tipo de dispositivo </th>
          <th> Mac Address </th>
          <th> Numero de Serie </th>
        </tr>
      </thead>
      <tbody v-for="device in this.autocomplete_list">
        <tr @click.prevent="select_element(device.id)" v-bind:class="{ 'is-selected': isElementSelected(device.id), 'disabled' : !device.available }" >
          <td> {{ device.device_model_name }} </td>
          <td> {{ device.device_type }} </td>
          <td> {{ device.mac_address }} </td>
          <td> {{ device.serial_number }} </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.disabled {
  color: #b5b5b5;
}
</style>

<script>

export default {
  props: ['list', 'form'],
  data: function () {
    return {
      selected_element: null,
      name: null
    }
  },
	computed: {
		autocomplete_list: function() {
			if (this.name == null || this.name == "") {
				return this.list;
			} else {
				return this.list.filter(device => device.mac_address.toLowerCase().indexOf(this.name.toLowerCase()) >= 0);
			}
		}
  },

  methods: {
/* estos metodos se pueden enviar a un modulo que sea parte de lista
tal vez lista se le puedan agregar metodos cuando se crea a partir de la carga de datos
new Lista = (data) pero tambien que tenga metodos
o si no nada mas cargarlos asi list_data y list  de cualquier forma ahorrariamos
list.confirm_delete("desea eliminar este dispositivo") bam

list.isElementSelected()
*/
    select_element: function(element_id) {
     this.selected_element = ( this.selected_element == element_id) ? null : element_id
    },
    isElementSelected: function(element_id) {
     return (this.selected_element == element_id)
    },

    confirm_delete: function(element_id) {
      this.$dialog.confirm({
        message: "Desea eliminar ese dispositivo?",
        onConfirm: () => {
          this.form.delete(element_id, this.list)
        }
      })
    }
  }
}
</script>
