export const interdigita_api = new Object({
  URL: process.env.API_URL,
  google_maps_key: process.env.G_MAPS_KEY,
  google_recaptcha_key: process.env.G_RECAPTCHA_KEY,
  google_recaptcha_uri: process.env.G_RECAPTCHA_URI,
  base_latitude: process.env.BASE_LAT,
  base_longitude: process.env.BASE_LONG
});
/*
  base_latitude: process.env.I_BASE_LAT,
  base_longitude: process.env.I_BASE_LONG
  URL: "https://192.168.10.20:3001/",
  google_maps_key: 'AIzaSyBaSmCt_tj9udi8uxF6Mx80OFpqTEeo49E',
  google_recaptcha_key: '6Ld7UlsUAAAAAJtt7aoVC1nLZEu51TmGoE4_OWxX',
  google_recaptcha_uri: '//www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=es',
  base_latitude: 21.163546,
  base_longitude: -86.826099
  */

