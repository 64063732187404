<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="payments_form" v-bind:list="list"
      v-bind:customer_id="this.customer_id" v-show="!payments_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="payments_form" v-bind:list="list"
       v-bind:customer_id="this.customer_id" v-if="payments_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../packs/form'
import {interdigita_api} from '../../../packs/api.js'

import payments_form from './payments/form'
import payments_list from './payments/list'

export default {
  props: ["customer_id"],
  data: function () {
    return {
      list: null,
      customers_list: null,
      payments_form: new Form({
        id: null,
        amount_formatted: null,
        amount: null,
        amount_value: null,
        amount_currency: null,
        date: new Date().toISOString(),
        customer_id: this.customer_id,
        customer_name: null,
        reference: null,
        confirmed: null,
        image: null,
        image_url: null,
        image_thumb_url: null
        },
        this,
        `customers/${this.customer_id}`, '/payments'
      )
    }
  },
  methods: {
    payments_list_get: function() {
      this.$http.get(`customers/${this.customer_id}/payments`).then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': payments_form,
    'list-component': payments_list
  },
  mounted() {
    this.payments_list_get()
    //customer name de donde?
  }
}
</script>
