<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title">
          <template v-if="form.mode == 'new'">
          Agregar nueva 
          </template>
          <template v-if="form.mode == 'edit'">
          Actualizar 
          </template>
         dirección de red 
        </h3>
      </div>
    </div>
    <form @keydown="errors.clear($event.target.name)">
      <section class="columns">
        <div class="column">

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="network_address_form_address">Direccion IP</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.address" placeholder="0.0.0.0" id="network_address_form_address" class="input" name="address">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                    <span class="help is-danger" v-if="errors.has('address')" v-text="errors.get('address')">
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="network_address_form_prefix">Prefijo</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.prefix" placeholder="" id="network_address_form_prefix" class="input" name="prefix">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                  <span class="help is-danger" v-if="errors.has('prefix')" v-text="errors.get('prefix')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="network_address_form_dhcp_pool">DHCP Pool?</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.dhcp_pool" placeholder="" id="network_address_form_dhcp_pool" type="checkbox" class="checkbox" name="dhcp_pool">
                  <span class="help is-danger" v-if="errors.has('dhcp_pool')" v-text="errors.get('dhcp_pool')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="network_address_form_description">Descripcion</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                    <input v-model="form.description" placeholder="" id="network_address_form_description" class="input" name="description">
                    <span class="icon is-small is-left">
                      <i class="fa fa-cog"></i>
                    </span>
                  <span class="help is-danger" v-if="errors.has('description')" v-text="errors.get('description')"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label" for="network_address_form_ip_address_group_id">Grupo</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <div class="select">
                  <select v-model="form.network_address_group_id"  id="network_address_form_network_address_group_id" class="input" name="network_address_group_id">
                    <option v-for="network_address_group in network_address_groups_list" :value="network_address_group.id" :key="network_address_group.id">
  {{ network_address_group.name }}</option>
                  </select>
                  </div>
                    <span class="icon is-small is-left">
                      <i class="fa fa-briefcase"></i>
                    </span>
                  <span class="help is-danger" v-if="errors.has('network_address_group_id')" v-text="errors.get('network_address_group_id')"></span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div class="level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" type="button" @click.prevent="form.cancel()">Cancelar</button>
          </div>
          <div class="level-item">
            <button class="button is-success" @click.prevent="form.submit(list)" :disabled="errors.any()">
              <template v-if="form.mode == 'new'">
                Crear
              </template>
              <template v-if="form.mode == 'edit'">
                Guardar Cambios
              </template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['form', 'list', 'network_address_groups_list'],
    data: function () {
      return {
        errors: this.form.errors
      }
    }
  }
</script>
