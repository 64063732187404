
import Vue from 'vue'

import VueResource from 'vue-resource'
import VueRouter from 'vue-router'

import Buefy from 'buefy'

import jwt_decode from 'jwt-decode'


import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment'

import VueMq from 'vue-mq'

import VeeValidate from 'vee-validate'
const VueValidationEs = require('vee-validate/dist/locale/es');


import Sticky from 'vue-sticky-directive'
import Multiselect from 'vue-multiselect'


import {interdigita_api} from './api.js'







Vue.use(Buefy, {
  defaultIconPack: 'fa'
})

Vue.use(VueResource)
Vue.http.options.root = interdigita_api.URL;

Vue.use(VueRouter)

Vue.use(VeeValidate, {
    locale: 'es',
    inject: false,
    dictionary: {
      es: VueValidationEs
    }
})


Vue.use(VueMq, {
  breakpoints: {
    small: 1024,
    desktop: Infinity
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    key: interdigita_api.google_maps_key
    // libraries: 'places,drawing,visualization'
  }
});

Vue.use(Sticky)
//Vue.component('v-select', vSelect)
Vue.component("multiselect", Multiselect);


window.Event = new Vue()  //cargar el bus de eventos antes que los componentes

Vue.prototype.moment = moment

moment.locale('es-us')


import authentication from './authentication'

// ROUTER COMPONENTS

import Login from '../components/Login.vue'
import Registration from '../components/Registration.vue'
import Internet from '../components/Internet.vue'

import Info from '../components/Info.vue'
import Welcome from '../components/Welcome.vue'
/*
import Settings from '../components/Settings.vue'
*/


import AdminDashboard from '../components/admin/AdminDashboard.vue'


import Customers from '../components/admin/Customers.vue'
import CustomerForm from '../components/admin/customers/form.vue'
import CustomerAccessControl from '../components/admin/customers/AccessControl.vue'
import CustomerTelephoneNumbers from '../components/admin/customers/TelephoneNumbers.vue'
import CustomerAddresses from '../components/admin/customers/Addresses.vue'
import CustomerPayments from '../components/admin/customers/Payments.vue'

import Payments from '../components/admin/Payments.vue'
//Staff Management  ..solo para admins..
import Staff from '../components/admin/Staff.vue'

//Inventory Management
import NetworkAddresses from '../components/admin/inventory/NetworkAddresses.vue'
import NetworkAddressesGroups from '../components/admin/inventory/network_addresses/Groups.vue'

import Devices from '../components/admin/inventory/Devices.vue'
import DeviceManufacturers from '../components/admin/inventory/devices/Manufacturers.vue'
import DeviceModels from '../components/admin/inventory/devices/Models.vue'


//Internet Service
import ServicePlans from '../components/admin/internet/ServicePlans'
import Installs from '../components/admin/internet/Installs'
import Subscriptions from '../components/admin/internet/Subscriptions'

//System

// NON ROUTER COMPONENTS
import AlertCenter from '../components/AlertCenter.vue'
import LoggedStatus from '../components/LoggedStatus.vue'
import NavigationHeader from '../components/Header.vue'


//ROUTER
//algunos mueven su router a su propio archivo
//
// component logout?

const routes = [
  {
    path: '/internet', component: Internet, name: 'internet'
  },

  {
    path: '/registro', component: Registration
  },

  {
    path: '/login', component: Login, name: 'login'
  },

  {
    //path: '/admin', component: AdminDashboard, meta: {auth: true},
    path: '/admin', component: AdminDashboard, name: 'admin', meta: {auth: ['admin']},

    children: [
      {
        path: 'customers',
        component: Customers,
        name: 'customers_list',
        meta: { auth:  ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'customers/new', 
        props: {form_mode: 'new'},
        component: CustomerForm,
        name: 'new_customer',
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'customers/:customer_id', 
        props: (route) => ({ form_mode: 'edit', customer_id: route.params.customer_id }),
        component: CustomerForm,
        name: 'customer',
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'customers/:customer_id/access_control',
        component: CustomerAccessControl,
        name: 'customer_access_control',
        props: (route) => ({ form_mode: 'edit', customer_id: route.params.customer_id }),
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'customers/:customer_id/telephone_numbers',
        component: CustomerTelephoneNumbers,
        name: 'customer_telephone_numbers',
        props: true,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'customers/:customer_id/addresses',
        component: CustomerAddresses,
        name: 'customer_addresses',
        props: true,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'customers/:customer_id/payments',
        component: CustomerPayments,
        name: 'customer_payments',
        props: true,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: '/payments',
        component: Payments,
        name: 'payments',
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'inventory/devices',
        component: Devices,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'inventory/devices/models',
        component: DeviceModels,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'inventory/devices/manufacturers',
        component: DeviceManufacturers,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'inventory/network_addresses',
        component: NetworkAddresses,
        meta: { secureRoute: true, authorizedRoles: ['admin',  'technician']  }
      },

      {
        path: 'inventory/network_addresses/groups',
        component: NetworkAddressesGroups,
        meta: { secureRoute: true, authorizedRoles: ['admin',  'technician']  }
      },

      {
        path: 'staff/users',
        component: Staff,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'internet/service_plans',
        component: ServicePlans,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'internet/installs',
        component: Installs,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      },

      {
        path: 'internet/subscriptions',
        component: Subscriptions,
        meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
      }


    ]
  },

  // is this old?
  /*  authorizedRoles is not being respected
   *
   * me gusta como encadena los componentes, puedo hacer esto?
  {
    path: '/admin/customers', component: CustomersList, 
    meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician'] },
    children: [{
      path: ':id',
      component: Customer,
      meta: { secureRoute: true, authorizedRoles: ['admin', 'accounting', 'sales', 'technician']  }
    }]
  },
    */

  {
    //porque no autentica?
    path: '/info', component: Info, name: 'info', meta: { secureRoute: true }
  },
  {
    path: '/welcome', component: Welcome, name: 'welcome', meta: { auth: true }
  }


  /*

  '/facturacion': {
    component: Facturacion
  },


  {
    path: '/settings', component: Settings
  },

  */
]

export const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

Vue.router = router

Vue.use(require('@websanova/vue-auth'), {
    auth: require('../vue-auth/drivers/bearer_interdigita.js'),
    http: require('@websanova/vue-auth/drivers/http/vue-resource.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),

    loginData: {url: 'user_token/', method: 'POST', fetchUser: false},
    logoutData: {redirect: '/', makeRequest: false},
    registerData: {url: 'registration/', method: 'POST', redirect: false, fetchUser: false},

    tokenStore: ['localStorage'],
    tokenDefaultName: 'jwt',
    rolesVar: 'roles',
    refreshData: {url: 'user_token/', method: 'GET', enabled: false, interval: 0},
    authRedirect:  {path: '/login'},
    fetchData: {
      enabled: false,
      success: function () {
        if (Vue.auth.check()) {
          let data = authentication.decode_token(Vue.auth.token());
          Vue.auth.watch.data = Vue.auth.user(data);
        } else {
          console.log('no autenticado: ');
        }
      }
    }


});


Vue.http.interceptors.push(function(request, next) {
	next(function (res) {
   
		if (res.status === 401 ) {
      if ( res.body.includes('Token Expired') ) {
        if (Vue.auth.check()) {
          authentication.expired_token_log_out(this, '/login')
				}
			}
		}
        
		/*
        else if (res.status === 500) {
            Vue.router.push({name: 'error-500'});
        }
*/
    });
});

//AUTHENTICATION GUARDS
/*
router.beforeEach((to, from, next) => {
  if (to.meta.secureRoute ) {
    if (authentication.user.authenticated) {
      next()
    } else {
      next({ name: 'login', params: { message: 'El recurso solicitado requiere identificación' }})
    }
  } else {
    next()
  }
  })
  */
/*
router.beforeEach((to, from, next) => {
  if (to.meta.secureRoute || to.meta.auth ) {
    if (authentication.user.authenticated) {
        next()
      if(to.meta.authorizedRoles) {
        if ( authentication.check_authorization(to.meta.authorizedRoles) ) {
          next()
        } else {
          console.log(authentication.user.roles)
          next({ name: 'index' , params: { message: 'El recurso solicitado no esta disponible' }})  //en realidad mandar al dashboard, 
        }
      } else {
        next()
      }

    } else {
      next({ name: 'login', params: { message: 'El recurso solicitado requiere identificación' }})
    }
  } else {
    next()
  }
  })

*/

//MAIN INSTANCE

const app = new Vue({
  router: router,
  components: { 
    'alert-center':      AlertCenter,
    'navigation-header': NavigationHeader
  }
}).$mount('#interdigita')


