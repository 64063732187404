<style>
.vue-map-container,
.vue-map-container .vue-map {
    width: 100%;
    height: 100%;
}
</style>
<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Direcciones </h3>
      </div>
    </div>
    <customer-navigation v-bind:selected_tab="'addresses_tab'" v-bind:customer_id="customer_id">
    </customer-navigation>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th>Direccion</th>
          <th>Coordenadas</th>
        </tr>
      </thead>
      <tbody v-for="address in list">
        <tr @click.prevent="select_element(address.id)" v-bind:class="{ 'is-selected': isElementSelected(address.id) }">
          <td>

            <span class="street_address">
               {{ address.street_address }}
            </span>
            <span class="secondary_address">
               {{ address.secondary_address }}
            </span>
            <span class="city_state_zip">
              {{ address.city }}, {{ address.state }}, {{ address.postal_code }}
            </span>
          </td>
          <td>
            <span class="coordinates">
              {{ address.latitude }}, {{ address.longitude }}

                <gmap-map style="width: 400px; height: 240px" class="map-container" :center="address_location(address)" :zoom="14">
                  <gmap-marker :position="address_location(address)"> </gmap-marker>
                </gmap-map>
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-success" @click.prevent="form.new">
            <span>Agregar Dirección</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import customer_navigation from './../navigation'

export default {
  props: ['list', 'form', 'customer_id'],
  data: function () {
    return {
      selected_element: null
    }
  },
  methods: {
    select_element: function(element_id) {
     this.selected_element = ( this.selected_element == element_id) ? null : element_id
    },
    isElementSelected: function(element_id) {
     return (this.selected_element == element_id)
    },
    confirm_delete: function(element_id) {
      this.$dialog.confirm({
        message: "Desea eliminar esta dirección?",
        onConfirm: () => {
          this.form.delete(element_id, this.list)
        }
      })
    },
    address_location: function(addr) {
      let location =  {
          lat: parseFloat(addr.latitude),
          lng: parseFloat(addr.longitude)
      }
      return location;
    }

  },
  components: {
    'customer-navigation': customer_navigation
  }
}
</script>
