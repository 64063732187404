<template>
  <div>
    <transition name="fade">
    <list-component v-bind:form="addresses_form" v-bind:list="list" 
      v-bind:customer_id="this.customer_id" v-show="!addresses_form.is_active">
      </list-component>
    </transition>
    <transition name="fade">
      <form-component v-bind:form="addresses_form" v-bind:list="list"
       v-bind:customer_id="this.customer_id" v-if="addresses_form.is_active">
      </form-component>
    </transition>
  </div>
</template>

<script>
import Form from '../../../packs/form'
import {interdigita_api} from '../../../packs/api.js'

import addresses_form from './addresses/form'
import addresses_list from './addresses/list'

export default {
  props: ["customer_id"],
  data: function () {
    return {
      list: null,
      addresses_form: new Form({
        id: null,
        street_address: null,
        secondary_address: null,
        city: null,
        state: 'Quintana Roo',
        country: 'Mexico',
        postal_code: null,
				latitude: interdigita_api.base_latitude,
				longitude: interdigita_api.base_longitude
        },
        this,
        `customers/${this.customer_id}`, '/addresses'
      )
    }
  },
  methods: {
    addresses_list_get: function() {
      this.$http.get(`customers/${this.customer_id}/addresses`).then(response => {
        this.list = response.body
        }, response => {
      })
    }
  },

  components: {
    'form-component': addresses_form,
    'list-component': addresses_list
  },
  mounted() {
    this.addresses_list_get()
  }
}
</script>
