<template>
  <div class="container is-fluid">
    <div class="level">
      <div class="level-item has-text-centered">
        <h3 class="title"> Numeros de Telefono </h3>
      </div>
    </div>
    <customer-navigation v-bind:selected_tab="'telephone_numbers_tab'" v-bind:customer_id="customer_id">
    </customer-navigation>
    <table class="table is-striped is-fullwidth is-bordered list">
      <thead>
        <tr>
          <th> Numero </th>
        </tr>
      </thead>
      <tbody v-for="telephone_number in list">
        <tr @click.prevent="select_element(telephone_number.id)" v-bind:class="{ 'is-selected': isElementSelected(telephone_number.id) }">
          <td>
            {{ telephone_number.number }}
           ( {{ telephone_number.name }} )

              <span class="tag is-success" v-if="telephone_number.is_mobile">
                <i class="fa fa-mobile fa-2x" style="margin-right: 0.25em;"></i>
                Celular
              </span>

              <span class="tag is-success" v-if="telephone_number.is_whatsapp">
                <i class="fa fa-whatsapp fa-2x" style="margin-right: 0.25em;"></i>
                WhatsApp
              </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan=3></td>
        </tr>
      </tfoot>
    </table>
    <div class="level is-mobile">
      <div class="level-left">
        <button class="button is-danger" @click.prevent="confirm_delete(selected_element)" v-if="selected_element">
          <b-icon icon="remove" pack="fa" size="is-small"> </b-icon>
          <span>Eliminar</span>
        </button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" @click.prevent="form.edit(selected_element, list)" v-if="selected_element">
            <b-icon icon="pencil" pack="fa" size="is-small"> </b-icon>
            <span>Editar</span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-success" @click.prevent="form.new">
            <span>Agregar Teléfono</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import customer_navigation from './../navigation'
export default {
  props: ['list', 'form', 'customer_id'],
  data: function () {
    return {
      selected_element: null
    }
  },
  methods: {
    select_element: function(element_id) {
     this.selected_element = ( this.selected_element == element_id) ? null : element_id
    },
    isElementSelected: function(element_id) {
     return (this.selected_element == element_id)
    },
    confirm_delete: function(element_id) {
      this.$dialog.confirm({
        message: "Desea eliminar ese número telefónico?",
        onConfirm: () => {
          this.form.delete(element_id, this.list)
        }
      })
    }

  },
  components: {
    'customer-navigation': customer_navigation
  }
}
</script>
